import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from './compose';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

ScrollToTop.defaultProps = {
    location: {},
    children: [],
};

ScrollToTop.propTypes = {
    location: PropTypes.object,
    children: PropTypes.arrayOf(PropTypes.object),
};

export default compose(withRouter)(ScrollToTop);
