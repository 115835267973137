import { prepearedVolumeSell } from '../constants';

const getPrepearedVolumeSellRequested = () => ({
    type: prepearedVolumeSell.GET_PREPEARED_VOLUME_SELL_REQUEST,
});

const getPrepearedVolumeSellLoaded = data => ({
    type: prepearedVolumeSell.GET_PREPEARED_VOLUME_SELL_SUCCESS,
    payload: data,
});

const getPrepearedVolumeSellError = error => ({
    type: prepearedVolumeSell.GET_PREPEARED_VOLUME_SELL_FAILURE,
    payload: error,
});

const getPrepearedVolumeAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getPrepearedVolumeSellRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getPrepearedVolumeSellLoaded(data));
        })
        .catch(err => {
            dispatch(getPrepearedVolumeSellError(err));
        });
};

export default getPrepearedVolumeAction;
