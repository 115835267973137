import { switchStatusStrategyConstants } from '../constants';

const statusStrategy = (state, action) => {
    if (state === undefined) {
        return {
            switchStatusStrategy: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case switchStatusStrategyConstants.SWITCH_STATUS_STRATEGY_REQUEST:
            return {
                switchStatusStrategy: {},
                loading: true,
                error: null,
                success: false,
            };

        case switchStatusStrategyConstants.SWITCH_STATUS_STRATEGY_SUCCESS:
            return {
                switchStatusStrategy: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case switchStatusStrategyConstants.SWITCH_STATUS_STRATEGY_FAILURE:
            return {
                switchStatusStrategy: {},
                loading: false,
                error: action.payload,
                success: false,
            };

        default:
            return state;
    }
};

export default statusStrategy;
