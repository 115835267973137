import React, { Component } from 'react';
import i18n from '../../i18n';
import USICON from '../assets/images/flags/english.svg';
import UAICON from '../assets/images/flags/ukraine.svg';
import style from './change-language.module.scss';

class SelectLangeage extends Component {
    state = {
        locale: '',
    };

    componentDidMount() {
        const locale = localStorage.getItem('i18nextLng');
        this.setState({
            locale,
        });
    }

    onSelectLang = countryCode => {
        i18n.changeLanguage(countryCode);
        localStorage.setItem('i18nextLng', countryCode);
        this.setState({
            locale: countryCode,
        });
    };

    render() {
        const { locale } = this.state;
        let flagLocale = '';
        let nextLang = '';

        if (locale === 'UA') {
            flagLocale = UAICON;
            nextLang = 'GB';
        }

        if (locale === 'GB') {
            flagLocale = USICON;
            nextLang = 'UA';
        }

        return (
            <div className={style.container} id="selectLangeage" onClick={() => this.onSelectLang(nextLang)}>
                <img className={style.container__flagIcon} src={flagLocale} alt="flag" />
            </div>
        );
    }
}

export default SelectLangeage;
