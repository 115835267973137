import { message } from 'antd';
import { deleteStrategyBotConstants } from '../constants';

const deleteStrategyBotRequested = () => ({
    type: deleteStrategyBotConstants.FETCH_DELETE_STRATEGY_BOT_REQUEST,
});

const deleteStrategyBotLoaded = data => ({
    type: deleteStrategyBotConstants.FETCH_DELETE_STRATEGY_BOT_SUCCESS,
    payload: data,
});

const deleteStrategyBotError = error => ({
    type: deleteStrategyBotConstants.FETCH_DELETE_STRATEGY_BOT_FAILURE,
    payload: error,
});

const deleteStrategyBotAction = deleteService => (deleteBtnId, t) => dispatch => {
    dispatch(deleteStrategyBotRequested());
    deleteService
        .deleteStrategyBot(deleteBtnId)
        .then(data => {
            dispatch(deleteStrategyBotLoaded(data));
            message.success(t('createBot.botDeleted'), 2);
        })
        .catch(err => {
            dispatch(deleteStrategyBotError(err));
        });
};

export default deleteStrategyBotAction;
