import { combineReducers } from 'redux';
import alert from './alert.reducer';
import authentication from './authentication.reducer';
import refreshToken from './jwt.reducer';
import allCoins from './getAllCoins.reducer';
import allSources from './getAllSources.reducer';
import getProviderBot from './getProviderBots.reducer';
import getStrategyBot from './getStrategyBots.reducer';
import getGeneratorBot from './getGeneratorBots.reducer';
import deleteProviderBot from './deleteProviderBot.reducer';
import deleteStrategyBot from './deleteStrategyBot.reducer';
import deleteGeneratorBot from './deleteGeneratorBot.reducer';
import statusProvider from './patchStatusProvider.reducer';
import statusStrategy from './patchStatusStrategy.reducer';
import statusGenerator from './patchStatusGenerator.reducer';
import createProviderBot from './postCreateProviderBot.reducer';
import createStrategyBot from './postCreateStrategyBot.reducer';
import createGeneratorBot from './postCreateGeneratorBot.reducer';
import editProviderBot from './patchEditProviderBot.reducer';
import editStrategyBot from './patchEditStrategyBot.reducer';
import editGeneratorBot from './patchEditGeneratorBot.reducer';
import stopAllProviderBots from './patchStopAllProviderBots.reducer';
import getPrepearedVolume from './getPrepearedVolume.reducer';
import getPrepearedVolumeBuy from './getPrepearedVolumeBuy.reducer';
import getSentVolumeSell from './getSentVolumeSell.reducer';
import getSentVolumeBuy from './getSentVolumeBuy.reducer';
import getAccuracyPreparedVolumeBuy from './getAccuracyPreparedVolumeBuy.reducer';
import getAccuracyPreparedVolumeSell from './getAccuracyPreparedVolumeSell.reducer';
import getAccuracySentVolumeBuy from './getAccuracySentVolumeBuy.reducer';
import getAccuracySentVolumeSell from './getAccuracySentVolumeSell.reducer';
import getConfiguredVolume from './getConfiguredVolume.reducer';
import getWorkLoadTimeLine from './getWorkLoadTimeLine.reducer';
import getLifeCycleTimeLine from './getLifeCycleTimeLine.reducer';
import commonPairs from './getCommonPairs.reducer';
import targetPairs from './getTargetPairs.reducer';
import savedOrderPair from './putSaveOrderPair.reducer';
import orders from './getOrders.reducer';
import getOneOrder from './getOneOrder.reducer';
import makeOrder from './postMakeOrder.reducer';
import cancelOrder from './cancelOrder.reducer';
import scheduledOrder from './postCreateScheduledOrder.reducer';
import getScheduledOrders from './getScheduledOrders.reducer';
import getOneScheduledOrders from './getOneScheduledOrders.reducer';
import updateScheduledOrder from './patchScheduledOrder.reducer';
import updateOrderStatus from './patchUpdateOrderStatus.reducer';
import deleteOrder from './deleteOrder.reducer';
import availableNewPairs from './getAvailableNewPairs.reducer';
import updateAllStatus from './putUpdateAllStatus.reducer';
import cancelBotOrders from './cancelBotOrders.reducer';
import currenciesAll from './getCurrenciesAll.reducer';
import stopAllStrategyBots from './patchStopAllStrategyBots.reducer';
import getDepthBot from './getDepthBots.reducer';
import deleteDepthBot from './deleteDepthBot.reducer';
import statusDepth from './patchStatusDepth.reducer';
import stopAllDepthBots from './patchStopAllDepthBots.reducer';
import createDepthBot from './postCreateDepthBot.reducer';
import editDepthBot from './patchEditDepthBot.reducer';

const rootReducer = combineReducers({
    authentication,
    alert,
    refreshToken,
    allCoins,
    allSources,
    getProviderBot,
    getGeneratorBot,
    deleteProviderBot,
    deleteStrategyBot,
    deleteGeneratorBot,
    statusProvider,
    statusStrategy,
    statusGenerator,
    createProviderBot,
    createStrategyBot,
    createGeneratorBot,
    editProviderBot,
    editStrategyBot,
    editGeneratorBot,
    stopAllProviderBots,
    stopAllStrategyBots,
    getPrepearedVolume,
    getPrepearedVolumeBuy,
    getSentVolumeSell,
    getSentVolumeBuy,
    getAccuracyPreparedVolumeBuy,
    getAccuracyPreparedVolumeSell,
    getAccuracySentVolumeBuy,
    getAccuracySentVolumeSell,
    getConfiguredVolume,
    getWorkLoadTimeLine,
    getStrategyBot,
    getDepthBot,
    getLifeCycleTimeLine,
    commonPairs,
    targetPairs,
    savedOrderPair,
    orders,
    getOneOrder,
    makeOrder,
    cancelOrder,
    scheduledOrder,
    getScheduledOrders,
    getOneScheduledOrders,
    updateScheduledOrder,
    updateOrderStatus,
    deleteOrder,
    availableNewPairs,
    updateAllStatus,
    cancelBotOrders,
    currenciesAll,
    deleteDepthBot,
    statusDepth,
    stopAllDepthBots,
    createDepthBot,
    editDepthBot,
});

export default rootReducer;
