import { message } from 'antd';
import { switchStatusStrategyConstants } from '../constants';

const switchStatusStrategyRequested = () => ({
    type: switchStatusStrategyConstants.SWITCH_STATUS_STRATEGY_REQUEST,
});

const switchStatusStrategyLoaded = data => ({
    type: switchStatusStrategyConstants.SWITCH_STATUS_STRATEGY_SUCCESS,
    payload: data,
});

const switchStatusStrategyError = error => ({
    type: switchStatusStrategyConstants.SWITCH_STATUS_STRATEGY_FAILURE,
    payload: error,
});

const switchStatusStrategyAction = patchService => (filterBotsdata, id, t) => dispatch => {
    dispatch(switchStatusStrategyRequested());
    patchService
        .switchStatusStrategy(filterBotsdata, id)
        .then(data => {
            dispatch(switchStatusStrategyLoaded(data));
            message.success(t('createBot.statusChanged'), 2);
        })
        .catch(err => {
            dispatch(switchStatusStrategyError(err));
        });
};

export default switchStatusStrategyAction;
