import { getOrdersConstants } from '../constants';

const orders = (state, action) => {
    if (state === undefined) {
        return {
            data: [],
            loading: true,
            error: false,
            success: false,
        };
    }

    switch (action.type) {
        case getOrdersConstants.GET_ORDERS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: false,
                success: true,
            };

        case getOrdersConstants.GET_ORDERS_FAILURE:
            return {
                data: [],
                loading: false,
                error: true,
                success: false,
            };

        default:
            return state;
    }
};

export default orders;
