import { accuracyPreparedVolumeSell } from '../constants';

const getAccuracyPreparedVolumeSellRequested = () => ({
    type: accuracyPreparedVolumeSell.GET_ACCURACY_PREPEARED_VOLUME_SELL_REQUEST,
});

const getAccuracyPreparedVolumeSellLoaded = data => ({
    type: accuracyPreparedVolumeSell.GET_ACCURACY_PREPEARED_VOLUME_SELL_SUCCESS,
    payload: data,
});

const getAccuracyPreparedVolumeSellError = error => ({
    type: accuracyPreparedVolumeSell.GET_ACCURACY_PREPEARED_VOLUME_SELL_FAILURE,
    payload: error,
});

const accuracyPreparedVolumeSellAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getAccuracyPreparedVolumeSellRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getAccuracyPreparedVolumeSellLoaded(data));
        })
        .catch(err => {
            dispatch(getAccuracyPreparedVolumeSellError(err));
        });
};

export default accuracyPreparedVolumeSellAction;
