import { message } from 'antd';
import { createStrategyBotConstant, personalAreaPath, strategyBotsPath } from '../constants';

const createStrategyBotRequested = () => ({
    type: createStrategyBotConstant.FETCH_CREATE_STRATEGY_BOT_REQUEST,
});

const createStrategyBotLoaded = data => ({
    type: createStrategyBotConstant.FETCH_CREATE_STRATEGY_BOT_SUCCESS,
    payload: data,
});

const createStrategyBotError = error => ({
    type: createStrategyBotConstant.FETCH_CREATE_STRATEGY_BOT_FAILURE,
    payload: error,
});

const fetchCreateStrategyBotAction = postService => (createNewBot, t, history) => dispatch => {
    dispatch(createStrategyBotRequested());
    postService
        .createBotStrategy(createNewBot)
        .then(data => {
            dispatch(createStrategyBotLoaded(data));
            message.success(t('createBot.successCreated'), 2);
            history.push(`${personalAreaPath}${strategyBotsPath}`);
        })
        .catch(err => {
            dispatch(createStrategyBotError(err));
            message.error(err.message, 2);
        });
};

export default fetchCreateStrategyBotAction;
