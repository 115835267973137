import { getStrategyBotConstants } from '../constants';

const strategyBotsRequested = () => ({
    type: getStrategyBotConstants.GET_STRATEGY_BOT_REQUEST,
});

const strategyBotsLoaded = data => ({
    type: getStrategyBotConstants.GET_STRATEGY_BOT_SUCCESS,
    payload: data,
});

const strategyBotsError = error => ({
    type: getStrategyBotConstants.GET_STRATEGY_BOT_FAILURE,
    payload: error,
});

const fetchStrategyBotsAction = getService => () => dispatch => {
    dispatch(strategyBotsRequested());
    getService
        .getStrategy()
        .then(data => {
            dispatch(strategyBotsLoaded(data));
        })
        .catch(err => {
            if (err?.response?.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem('updateTokenTime');
                document.location.reload(true);
            }
            dispatch(strategyBotsError(err));
        });
};

export default fetchStrategyBotsAction;
