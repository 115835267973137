import { message } from 'antd';
import { updateScheduledOrderConstants } from '../constants';

const updateScheduledOrderRequested = () => ({
    type: updateScheduledOrderConstants.UPDATE_SCHEDULED_ORDER_REQUEST,
});

const updateScheduledOrderLoaded = data => ({
    type: updateScheduledOrderConstants.UPDATE_SCHEDULED_ORDER_SUCCESS,
    payload: data,
});

const updateScheduledOrderError = error => ({
    type: updateScheduledOrderConstants.UPDATE_SCHEDULED_ORDER_FAILURE,
    payload: error,
});

const updateScheduledOrderAction = postService => (updateData, t) => dispatch => {
    dispatch(updateScheduledOrderRequested());
    postService
        .updateScheduledOrder(updateData)
        .then(data => {
            dispatch(updateScheduledOrderLoaded(data));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(updateScheduledOrderError(err));
        });
};

export default updateScheduledOrderAction;
