import { message } from 'antd';
import { stopAllBotsDepthConstants } from '../constants';

const stopAllBotsRequested = () => ({
    type: stopAllBotsDepthConstants.STOP_ALL_DEPTH_REQUEST,
});

const stopAllBotsRequestedLoaded = data => ({
    type: stopAllBotsDepthConstants.STOP_ALL_DEPTH_SUCCESS,
    payload: data,
});

const stopAllBotsRequestedError = error => ({
    type: stopAllBotsDepthConstants.STOP_ALL_DEPTH_FAILURE,
    payload: error,
});

const stopAllBotsDepthAction = patchService => (body, t) => dispatch => {
    dispatch(stopAllBotsRequested());
    patchService
        .stopAllDepth(body)
        .then(data => {
            dispatch(stopAllBotsRequestedLoaded(data));
            if (body.status === 'RUNNING') {
                message.success(t('createBot.allBotsRunning'), 2);
            } else if (body.status === 'STOPPED') {
                message.success(t('createBot.allBotsStopped'), 2);
            }
        })
        .catch(err => {
            dispatch(stopAllBotsRequestedError(err));
        });
};

export default stopAllBotsDepthAction;
