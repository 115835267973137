import { getTargetPairsConstants } from '../constants';

const getTargetPairsRequested = () => ({
    type: getTargetPairsConstants.GET_TARGET_PAIRS_REQUEST,
});

const getTargetPairsLoaded = data => ({
    type: getTargetPairsConstants.GET_TARGET_PAIRS_SUCCESS,
    payload: data,
});

const getTargetPairsError = error => ({
    type: getTargetPairsConstants.GET_TARGET_PAIRS_FAILURE,
    payload: error,
});

const fetchTargetPairsAction = getService => () => dispatch => {
    dispatch(getTargetPairsRequested());
    getService
        .getTargetPairs()
        .then(data => {
            dispatch(getTargetPairsLoaded(data));
        })
        .catch(err => {
            dispatch(getTargetPairsError(err));
        });
};

export default fetchTargetPairsAction;
