import { accuracyPreparedVolumeBuy } from '../constants';

const getAccuracyPreparedVolumeBuy = (state, action) => {
    if (state === undefined) {
        return {
            accuracyPreparedVolumeBuy: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
        case accuracyPreparedVolumeBuy.GET_ACCURACY_PREPEARED_VOLUME_BUY_REQUEST:
            return {
                accuracyPreparedVolumeBuy: [],
                loading: true,
                error: false,
            };

        case accuracyPreparedVolumeBuy.GET_ACCURACY_PREPEARED_VOLUME_BUY_SUCCESS:
            return {
                accuracyPreparedVolumeBuy: action.payload,
                loading: false,
                error: false,
            };

        case accuracyPreparedVolumeBuy.GET_ACCURACY_PREPEARED_VOLUME_BUY_FAILURE:
            return {
                accuracyPreparedVolumeBuy: [],
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};

export default getAccuracyPreparedVolumeBuy;
