import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import style from './green-red-button-block.module.scss';

const GreenRedButtonBlock = props => {
    const {
        clickGreenBtn,
        clickRedBtn,
        greenBtnText,
        redBtnText,
        disabled,
        botsData,
        hideIfLessOne,
        greenRedButtonWrapper,
    } = props;

    return (
        <div className={greenRedButtonWrapper}>
            <Button
                onClick={clickGreenBtn}
                className={style.greenRedButtonBlockWrapper__createNew}
                type="button"
                disabled={disabled}
            >
                {greenBtnText}
            </Button>
            {hideIfLessOne && botsData.length < 1 ? null : (
                <Button onClick={clickRedBtn} className={style.greenRedButtonBlockWrapper__stopAll} type="button">
                    {redBtnText}
                </Button>
            )}
        </div>
    );
};

GreenRedButtonBlock.defaultProps = {
    clickGreenBtn: () => {},
    clickRedBtn: () => {},
    greenBtnText: '',
    redBtnText: '',
    disabled: false,
    hideIfLessOne: false,
    botsData: [],
    greenRedButtonWrapper: {},
};

GreenRedButtonBlock.propTypes = {
    clickGreenBtn: PropTypes.func,
    clickRedBtn: PropTypes.func,
    greenBtnText: PropTypes.string,
    redBtnText: PropTypes.string,
    disabled: PropTypes.bool,
    hideIfLessOne: PropTypes.bool,
    botsData: PropTypes.arrayOf(PropTypes.object),
    greenRedButtonWrapper: PropTypes.any,
};

export default GreenRedButtonBlock;
