import { message } from 'antd';
import { switchStatusConstants } from '../constants';

const switchStatusProviderRequested = () => ({
    type: switchStatusConstants.SWITCH_STATUS_REQUEST,
});

const switchStatusProviderLoaded = data => ({
    type: switchStatusConstants.SWITCH_STATUS_SUCCESS,
    payload: data,
});

const switchStatusProviderError = error => ({
    type: switchStatusConstants.SWITCH_STATUS_FAILURE,
    payload: error,
});

const switchStatusProviderAction = patchService => (filterBotsdata, id, t) => dispatch => {
    dispatch(switchStatusProviderRequested());
    patchService
        .switchStatusProvider(filterBotsdata, id)
        .then(data => {
            dispatch(switchStatusProviderLoaded(data));
            message.success(t('createBot.statusChanged'), 2);
        })
        .catch(err => {
            dispatch(switchStatusProviderError(err));
        });
};

export default switchStatusProviderAction;
