import { getAllSourcesConstants } from '../constants';

const getAllSourcesRequested = () => ({
    type: getAllSourcesConstants.GET_SOURCES_REQUEST,
});

const getAllSourcesLoaded = data => ({
    type: getAllSourcesConstants.GET_SOURCES_SUCCESS,
    payload: data,
});

const getAllSourcesError = error => ({
    type: getAllSourcesConstants.GET_SOURCES_FAILURE,
    payload: error,
});

const fetchAllSourcesAction = getService => () => dispatch => {
    dispatch(getAllSourcesRequested());
    getService
        .getAllSources()
        .then(data => {
            dispatch(getAllSourcesLoaded(data));
        })
        .catch(err => {
            dispatch(getAllSourcesError(err));
        });
};

export default fetchAllSourcesAction;
