const personalAreaPath = '/personal-area';
const providerBotsPath = '/liquidity-provider-bots';
const strategyBotsPath = '/strategy-bots';
const depthBotsPath = '/depth-bots';
const providerBotsCreatePath = '/liquidity-provider-create-bot';
const strategyBotsCreatePath = '/strategy-create-bot';
const depthBotsCreatePath = '/depth-create-bot';
const providerBotsEditPath = '/liquidity-provider-edit-bot';
const strategyBotsEditPath = '/strategy-edit-bot';
const depthBotsEditPath = '/depth-edit-bot';
const providerBotsStatistics = '/liquidity-provider-statistics';
const ordersPath = '/orders';
const createNewOrderPath = '/create-new-order';
const editOrderPath = '/edit-order';

export {
    personalAreaPath,
    providerBotsPath,
    strategyBotsPath,
    providerBotsCreatePath,
    strategyBotsCreatePath,
    providerBotsEditPath,
    strategyBotsEditPath,
    depthBotsPath,
    depthBotsCreatePath,
    depthBotsEditPath,
    providerBotsStatistics,
    ordersPath,
    createNewOrderPath,
    editOrderPath,
};
