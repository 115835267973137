import { message } from 'antd';
import { cancelOrderConstants } from '../constants';

const cancelOrderRequested = () => ({
    type: cancelOrderConstants.FETCH_CANCEL_ORDER_REQUEST,
});

const cancelOrderLoaded = data => ({
    type: cancelOrderConstants.FETCH_CANCEL_ORDER_SUCCESS,
    payload: data,
});

const cancelOrderError = error => ({
    type: cancelOrderConstants.FETCH_CANCEL_ORDER_FAILURE,
    payload: error,
});

const cancelOrderAction = deleteService => (pairname, id, t) => dispatch => {
    dispatch(cancelOrderRequested());
    deleteService
        .cancelOrder(pairname, id)
        .then(item => {
            dispatch(cancelOrderLoaded(item));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(cancelOrderError(err));
        });
};

export default cancelOrderAction;
