import { deleteStrategyBotConstants } from '../constants';

const deleteStrategyBot = (state, action) => {
    if (state === undefined) {
        return {
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case deleteStrategyBotConstants.FETCH_DELETE_STRATEGY_BOT_REQUEST:
            return {
                loading: true,
                error: null,
                success: false,
            };

        case deleteStrategyBotConstants.FETCH_DELETE_STRATEGY_BOT_SUCCESS:
            return {
                loading: false,
                error: null,
                success: true,
            };

        case deleteStrategyBotConstants.FETCH_DELETE_STRATEGY_BOT_FAILURE:
            return {
                loading: false,
                error: true,
                success: false,
            };

        default:
            return state;
    }
};

export default deleteStrategyBot;
