import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from '../layouts/header';
import { HomePage, PageNotFound, PersonalArea } from '../pages';

import ScrollToTop from '../../utils/scrollToTop';

import { personalAreaPath } from '../../constants';

import '../assets/styles/reset.scss';
import './app.scss';
import '../assets/styles/fonts.scss';

const App = () => (
    <Router>
        <ScrollToTop>
            <Header />
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path={personalAreaPath} component={PersonalArea} />
                <Route component={PageNotFound} />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
