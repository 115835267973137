import { message } from 'antd';
import { UPDATE_ALL_STATUS_CONSTANTS } from '../constants';

const updateAllStatusRequested = () => ({
    type: UPDATE_ALL_STATUS_CONSTANTS.UPDATE_ALL_STATUS_REQUEST,
});

const updateAllStatusLoaded = data => ({
    type: UPDATE_ALL_STATUS_CONSTANTS.UPDATE_ALL_STATUS_SUCCESS,
    payload: data,
});

const updateAllStatusError = error => ({
    type: UPDATE_ALL_STATUS_CONSTANTS.UPDATE_ALL_STATUS_FAILURE,
    payload: error,
});

const updateAllStatusAction = postService => (status, t) => dispatch => {
    dispatch(updateAllStatusRequested());
    postService
        .updateAllStatus(status)
        .then(data => {
            dispatch(updateAllStatusLoaded(data));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(updateAllStatusError(err));
            message.error(err.message, 2);
        });
};

export default updateAllStatusAction;
