import { getOneOrderConstants } from '../constants';

const getOneOrderRequested = () => ({
    type: getOneOrderConstants.GET_ONE_ORDER_REQUEST,
});

const getOneOrderLoaded = data => ({
    type: getOneOrderConstants.GET_ONE_ORDER_SUCCESS,
    payload: data,
});

const getOneOrderError = error => ({
    type: getOneOrderConstants.GET_ONE_ORDER_FAILURE,
    payload: error,
});

const fetchOneOrderAction = getService => pairName => dispatch => {
    dispatch(getOneOrderRequested());
    getService
        .getOneOrder(pairName)
        .then(data => {
            dispatch(getOneOrderLoaded(data));
        })
        .catch(err => {
            dispatch(getOneOrderError(err));
        });
};

export default fetchOneOrderAction;
