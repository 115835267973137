import { message } from 'antd';
import { CANCEL_DEPTH_BOT_ORDERS } from '../constants';

const cancelDepthBotOrdersRequested = () => ({
    type: CANCEL_DEPTH_BOT_ORDERS.FETCH_CANCEL_DEPTH_BOT_ORDERS_REQUEST,
});

const cancelDepthBotOrdersLoaded = data => ({
    type: CANCEL_DEPTH_BOT_ORDERS.FETCH_CANCEL_DEPTH_BOT_ORDERS_SUCCESS,
    payload: data,
});

const cancelDepthBotOrdersError = error => ({
    type: CANCEL_DEPTH_BOT_ORDERS.FETCH_CANCEL_DEPTH_BOT_ORDERS_FAILURE,
    payload: error,
});

const cancelDepthBotOrdersAction = deleteService => (pairname, t) => dispatch => {
    dispatch(cancelDepthBotOrdersRequested());
    deleteService
        .cancelDepthBotOrders(pairname)
        .then(item => {
            dispatch(cancelDepthBotOrdersLoaded(item));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(cancelDepthBotOrdersError(err));
            message.error(err.response.data.message, 2);
        });
};

export default cancelDepthBotOrdersAction;
