import { switchStatusConstants } from '../constants';

const statusProvider = (state, action) => {
    if (state === undefined) {
        return {
            switchStatusProvider: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case switchStatusConstants.SWITCH_STATUS_REQUEST:
            return {
                switchStatusProvider: {},
                loading: true,
                error: null,
                success: false,
            };

        case switchStatusConstants.SWITCH_STATUS_SUCCESS:
            return {
                switchStatusProvider: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case switchStatusConstants.SWITCH_STATUS_FAILURE:
            return {
                switchStatusProvider: {},
                loading: false,
                error: action.payload,
                success: false,
            };

        default:
            return state;
    }
};

export default statusProvider;
