import { accuracySentVolumeSell } from '../constants';

const getAccuracySentVolumeSellRequested = () => ({
    type: accuracySentVolumeSell.GET_ACCURACY_SENT_VOLUME_SELL_REQUEST,
});

const getAccuracySentVolumeSellLoaded = data => ({
    type: accuracySentVolumeSell.GET_ACCURACY_SENT_VOLUME_SELL_SUCCESS,
    payload: data,
});

const getAccuracySentVolumeSellError = error => ({
    type: accuracySentVolumeSell.GET_ACCURACY_SENT_VOLUME_SELL_FAILURE,
    payload: error,
});

const accuracySentVolumeSellAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getAccuracySentVolumeSellRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getAccuracySentVolumeSellLoaded(data));
        })
        .catch(err => {
            dispatch(getAccuracySentVolumeSellError(err));
        });
};

export default accuracySentVolumeSellAction;
