import { sentVolumeBuy } from '../constants';

const getSentVolumeBuy = (state, action) => {
    if (state === undefined) {
        return {
            getSentVolumeBuy: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
        case sentVolumeBuy.GET_SENT_VOLUME_BUY_REQUEST:
            return {
                getSentVolumeBuy: [],
                loading: true,
                error: false,
            };

        case sentVolumeBuy.GET_SENT_VOLUME_BUY_SUCCESS:
            return {
                getSentVolumeBuy: action.payload,
                loading: false,
                error: false,
            };

        case sentVolumeBuy.GET_SENT_VOLUME_BUY_FAILURE:
            return {
                getSentVolumeBuy: [],
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};

export default getSentVolumeBuy;
