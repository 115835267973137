import { lifeCycleTimeLine } from '../constants';

const getLifeCycleTimeLine = (state, action) => {
    if (state === undefined) {
        return {
            lifeCycleTimeLine: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
        case lifeCycleTimeLine.LIFE_CYCLE_TIME_LINE_REQUEST:
            return {
                lifeCycleTimeLine: [],
                loading: true,
                error: false,
            };

        case lifeCycleTimeLine.LIFE_CYCLE_TIME_LINE_SUCCESS:
            return {
                lifeCycleTimeLine: action.payload,
                loading: false,
                error: false,
            };

        case lifeCycleTimeLine.LIFE_CYCLE_TIME_LINE_FAILURE:
            return {
                lifeCycleTimeLine: [],
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};

export default getLifeCycleTimeLine;
