const createProviderBotInputs = [
    {
        value: 'frequencySeconds',
        label: 'createBot.setFrequencyOrderbookRefreshing',
        error: 'frequencySecondsError',
        type: 'number',
    },
    {
        value: 'pricePercents',
        label: 'createBot.set%MarginPrice',
        error: 'pricePercentsError',
        type: 'number',
    },
    {
        value: 'minOrderAmount',
        label: 'createBot.minOrderAmount',
        error: 'minOrderAmountError',
        type: 'number',
    },
    {
        value: 'maxOrderAmount',
        label: 'createBot.maxOrderAmount',
        error: 'maxOrderAmountError',
        type: 'number',
    },
    {
        value: 'minPercentBetweenOrdersPrice',
        label: 'createBot.minPercentBetweenOrdersPrice',
        error: 'minPercentBetweenOrdersPriceError',
        type: 'number',
    },
    {
        value: 'maxPercentBetweenOrdersPrice',
        label: 'createBot.maxPercentBetweenOrdersPrice',
        error: 'maxPercentBetweenOrdersPriceError',
        type: 'number',
    },
    {
        value: 'orderBookDepth',
        label: 'createBot.setDepthOrderBook',
        error: 'orderBookDepthError',
        type: 'number',
    },
    {
        value: 'volumePerDay',
        label: 'createBot.setVolumeTradedperDay',
        error: 'volumePerDayError',
        type: 'number',
    },
    {
        value: 'quantityPerOrderPercent',
        label: 'createBot.setMaxQuantityOrderIn%',
        error: 'quantityPerOrderPercentError',
        type: 'number',
    },
    {
        value: 'minOrderQuantity',
        label: 'createBot.minOrderQuantity',
        error: 'minOrderQuantityError',
        type: 'number',
    },
    {
        value: 'amountLimitFromSource',
        label: 'createBot.amountLimitFromSource',
        error: 'amountLimitFromSourceError',
        type: 'number',
    },
    {
        value: 'minTimeActivity',
        label: 'createBot.minTimeActivity',
        error: 'minTimeActivityError',
        type: 'number',
    },
    {
        value: 'maxTimeActivity',
        label: 'createBot.maxTimeActivity',
        error: 'maxTimeActivityError',
        type: 'number',
    },
    {
        value: 'userId',
        label: 'createBot.userId',
        error: 'userIdError',
        type: 'number',
    },
];

const createStrategyBotInputs = [
    {
        value: 'frequencySecondsMin',
        label: 'createBot.frequencySecondsMin',
        error: 'frequencySecondsMin',
        type: 'number',
    },
    {
        value: 'frequencySecondsMax',
        label: 'createBot.frequencySecondsMax',
        error: 'frequencySecondsMax',
        type: 'number',
    },
    {
        value: 'minPrice',
        label: 'createBot.minPrice',
        error: 'minPrice',
        type: 'number',
    },
    {
        value: 'maxPrice',
        label: 'createBot.maxPrice',
        error: 'maxPrice',
        type: 'number',
    },
    {
        value: 'minTradeAmount',
        label: 'createBot.minTradeAmount',
        error: 'minTradeAmount',
        type: 'number',
    },
    {
        value: 'maxTradeAmount',
        label: 'createBot.maxTradeAmount',
        error: 'maxTradeAmount',
        type: 'number',
    },
    {
        value: 'userId',
        label: 'createBot.userId',
        error: 'userId',
        type: 'number',
    },
];

const createDepthBotInputs = [
    {
        value: 'depth',
        label: 'createBot.depth',
        error: 'depth',
        type: 'number',
    },
    {
        value: 'minOrderAmount',
        label: 'createBot.minOrderAmount',
        error: 'minOrderAmount',
        type: 'number',
    },
    {
        value: 'maxOrderAmount',
        label: 'createBot.maxOrderAmount',
        error: 'maxOrderAmount',
        type: 'number',
    },
    {
        value: 'minPercentBetweenOrdersPrice',
        label: 'createBot.minPercentBetweenOrdersPrice',
        error: 'minPercentBetweenOrdersPrice',
        type: 'number',
    },
    {
        value: 'maxPercentBetweenOrdersPrice',
        label: 'createBot.maxPercentBetweenOrdersPrice',
        error: 'maxPercentBetweenOrdersPrice',
        type: 'number',
    },
    {
        value: 'spreadPriceDistancePercent',
        label: 'createBot.spreadPriceDistancePercent',
        error: 'spreadPriceDistancePercent',
        type: 'number',
    },
    {
        value: 'refreshSeconds',
        label: 'createBot.refreshSeconds',
        error: 'refreshSeconds',
        type: 'number',
    },
    {
        value: 'minTimeVolumeTradeSeconds',
        label: 'createBot.minTimeVolumeTradeSeconds',
        error: 'minTimeVolumeTradeSeconds',
        type: 'number',
    },
    {
        value: 'maxTimeVolumeTradeSeconds',
        label: 'createBot.maxTimeVolumeTradeSeconds',
        error: 'maxTimeVolumeTradeSeconds',
        type: 'number',
    },
    {
        value: 'minAmountPerTrade',
        label: 'createBot.minAmountPerTrade',
        error: 'minAmountPerTrade',
        type: 'number',
    },
    {
        value: 'dailyVolume',
        label: 'createBot.dailyVolume',
        error: 'dailyVolume',
        type: 'number',
    },
    {
        value: 'maxDailyVolumeDeviationPercent',
        label: 'createBot.maxDailyVolumeDeviationPercent',
        error: 'maxDailyVolumeDeviationPercent',
        type: 'number',
    },
    {
        value: 'userId',
        label: 'createBot.userId',
        error: 'userId',
        type: 'number',
    },
    {
        value: 'sourceVolumePercent',
        label: 'createBot.sourceVolumePercent',
        error: 'sourceVolumePercent',
        type: 'number',
    },
];

export { createProviderBotInputs, createStrategyBotInputs, createDepthBotInputs };
