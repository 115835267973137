/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, Select } from 'antd';
import classNames from 'classnames';

import Field from '../../../../UI/field';
import { createDepthBotInputs } from '../../../../../helpers/createProviderBotInputs';

import style from '../create-provider-bot-page/create-provider-bot-view.module.scss';
import '../create-provider-bot-page/create-provider-bot-view.scss';
import GreenRedButtonBlock from '../../../../UI/green-red-button-block';

const EditDepthBotView = ({
    createNewBot: { priceSource, id, pairSubstituteEnabled, tradingOnTargetExchangeEnabled, sourceVolumeEnabled },
    errors: { sourceError, pairNameError },
    t,
    errors,
    createNewBot,
    inputOnchange,
    selectOnChange,
    cancelCreateBot,
    submitcreateNewBot,
    editDepthBotLoading,
    allSources,
    commonPairs,
    targetPairs,
    switchTradingOnTargetExchangeEnabled,
    switchSourceVolumeEnabled,
}) => {
    const { Option } = Select;

    const isPairSubstituteEnabled = pairSubstituteEnabled ? targetPairs : commonPairs;

    return (
        <form className={style.createNewBot}>
            <div className={style.createNewBot__wrapper}>
                <div className={style.createNewBot__wrapper_leftSide}>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('createBot.setSourceLiquidity')}</p>
                        <Select
                            value={priceSource}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {allSources && allSources.length
                                ? allSources?.map(item => (
                                      <Option key={item} name="priceSource" value={item}>
                                          {item}
                                      </Option>
                                  ))
                                : null}
                        </Select>
                        {sourceError ? (
                            <div className={style.createNewBot__wrapperInput_invalid}>{sourceError}</div>
                        ) : null}
                    </div>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('generals.pair')}</p>
                        <Select
                            value={id || undefined}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {isPairSubstituteEnabled.map(items => {
                                const { left, right } = items;
                                return (
                                    <Option key={`${left}-${right}`} name="id" value={`${left}-${right}`}>
                                        {`${left}-${right}`}
                                    </Option>
                                );
                            })}
                        </Select>
                        {pairNameError ? (
                            <div className={style.createNewBot__wrapperInput_invalid}>{pairNameError}</div>
                        ) : null}
                    </div>

                    {createDepthBotInputs.map(item => {
                        const { value, label, error, type } = item;

                        return (
                            <div key={value} className={style.createNewBot__wrapperInput}>
                                <Field
                                    id={value}
                                    type={type}
                                    disabled={value === 'sourceVolumePercent' && !sourceVolumeEnabled}
                                    placeholder={t('generals.typeHere')}
                                    name={value}
                                    value={createNewBot[value] ?? undefined}
                                    error={errors[error]}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t(`${label}`)}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                        );
                    })}
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            className={style.antCheckboxSourceVolume}
                            checked={tradingOnTargetExchangeEnabled}
                            onChange={switchTradingOnTargetExchangeEnabled}
                        >
                            {t('createBot.tradingOnTargetExchangeEnabled')}
                        </Checkbox>
                    </div>
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            className={style.antCheckboxSourceVolume}
                            checked={sourceVolumeEnabled}
                            onChange={switchSourceVolumeEnabled}
                        >
                            {t('createBot.sourceVolumeEnabled')}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <GreenRedButtonBlock
                greenRedButtonWrapper={style.greenRedButtonWrapper}
                clickGreenBtn={submitcreateNewBot}
                clickRedBtn={cancelCreateBot}
                greenBtnText={t('generals.save')}
                redBtnText={t('generals.cancel')}
                disabled={editDepthBotLoading}
            />
        </form>
    );
};

EditDepthBotView.defaultProps = {
    t: () => {},
    inputOnchange: () => {},
    selectOnChange: () => {},
    cancelCreateBot: () => {},
    submitcreateNewBot: () => {},
    switchTradingOnTargetExchangeEnabled: () => {},
    switchSourceVolumeEnabled: () => {},
    errors: {},
    createNewBot: {},
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    editDepthBotLoading: false,
};

EditDepthBotView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    cancelCreateBot: PropTypes.func,
    submitcreateNewBot: PropTypes.func,
    switchTradingOnTargetExchangeEnabled: PropTypes.func,
    switchSourceVolumeEnabled: PropTypes.func,
    errors: PropTypes.object,
    allSources: PropTypes.arrayOf(PropTypes.string),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    createNewBot: PropTypes.object,
    editDepthBotLoading: PropTypes.bool,
};

export default withTranslation()(EditDepthBotView);
