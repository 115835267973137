import { editBotConstants } from '../constants';

const editStrategyBot = (state, action) => {
    if (state === undefined) {
        return {
            editStrategyBot: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case editBotConstants.EDIT_BOT_REQUEST:
            return {
                editStrategyBot: {},
                loading: true,
                error: null,
                success: false,
            };

        case editBotConstants.EDIT_BOT_SUCCESS:
            return {
                editStrategyBot: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case editBotConstants.EDIT_BOT_FAILURE:
            return {
                editStrategyBot: {},
                loading: false,
                error: action.payload,
                success: false,
            };

        default:
            return state;
    }
};

export default editStrategyBot;
