import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { compose } from '../../../utils';
import FirstScreen from './first-screen';
import { personalAreaPath, providerBotsPath } from '../../../constants';

const HomePage = ({ loggedIn }) => {
    if (!loggedIn) {
        return <FirstScreen />;
    }

    return <Redirect to={`${personalAreaPath}${providerBotsPath}`} />;
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

HomePage.defaultProps = {
    loggedIn: false,
};

HomePage.propTypes = {
    loggedIn: PropTypes.bool,
};

export default compose(connect(mapStateToProps))(HomePage);
