import { getCommonPairsConstants } from '../constants';

const commonPairs = (state, action) => {
    if (state === undefined) {
        return {
            commonPairs: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
        case getCommonPairsConstants.GET_COMMON_PAIRS_REQUEST:
            return {
                commonPairs: [],
                loading: true,
                error: false,
            };

        case getCommonPairsConstants.GET_COMMON_PAIRS_SUCCESS:
            return {
                commonPairs: action.payload,
                loading: false,
                error: false,
            };

        case getCommonPairsConstants.GET_COMMON_PAIRS_FAILURE:
            return {
                commonPairs: [],
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};

export default commonPairs;
