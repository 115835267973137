import { message } from 'antd';
import { UPDATE_ORDER_STATUS } from '../constants';

const updateOrderStatusRequested = () => ({
    type: UPDATE_ORDER_STATUS.UPDATE_ORDER_STATUS_REQUEST,
});

const updateOrderStatusLoaded = data => ({
    type: UPDATE_ORDER_STATUS.UPDATE_ORDER_STATUS_SUCCESS,
    payload: data,
});

const updateOrderStatusError = error => ({
    type: UPDATE_ORDER_STATUS.UPDATE_ORDER_STATUS_FAILURE,
    payload: error,
});

const updateOrderStatusAction = postService => (pairName, status, t) => dispatch => {
    dispatch(updateOrderStatusRequested());
    postService
        .updateOrderStatus(pairName, status)
        .then(response => {
            dispatch(updateOrderStatusLoaded(response));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(updateOrderStatusError(err));
            message.error(err.response.data.message, 2);
        });
};

export default updateOrderStatusAction;
