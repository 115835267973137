/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Select, Checkbox } from 'antd';

import Field from '../../../../UI/field';
import { createStrategyBotInputs } from '../../../../../helpers/createProviderBotInputs';

import style from '../create-provider-bot-page/create-provider-bot-view.module.scss';
import '../create-provider-bot-page/create-provider-bot-view.scss';
import GreenRedButtonBlock from '../../../../UI/green-red-button-block';

const CreateStrategyBotView = ({
    createNewBot: { priceSource, id, priceFromSource, pairSubstituteEnabled },
    errors: { sourceError, pairNameError },
    t,
    errors,
    createNewBot,
    inputOnchange,
    selectOnChange,
    switchSourceVolume,
    cancelCreateBot,
    submitcreateNewBot,
    createStrategyBotLoading,
    allSources,
    commonPairs,
    targetPairs,
}) => {
    const { Option } = Select;

    const isPairSubstituteEnabled = pairSubstituteEnabled ? targetPairs : commonPairs;

    return (
        <form className={style.createNewBot}>
            <div className={style.createNewBot__wrapper}>
                <div className={style.createNewBot__wrapper_leftSide}>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('createBot.setSourceLiquidity')}</p>
                        <Select
                            value={priceSource}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {allSources.map(item => (
                                <Option key={item} name="priceSource" value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                        {sourceError ? (
                            <div className={style.createNewBot__wrapperInput_invalid}>{sourceError}</div>
                        ) : null}
                    </div>

                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('generals.pair')}</p>
                        <Select
                            value={id || undefined}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {isPairSubstituteEnabled.map(items => {
                                const { left, right } = items;
                                return (
                                    <Option key={`${left}-${right}`} name="id" value={`${left}-${right}`}>
                                        {`${left}-${right}`}
                                    </Option>
                                );
                            })}
                        </Select>
                        {pairNameError ? (
                            <div className={style.createNewBot__wrapperInput_invalid}>{pairNameError}</div>
                        ) : null}
                    </div>

                    {createStrategyBotInputs.map(item => {
                        const { value, label, error, type } = item;

                        return (
                            <div key={value} className={style.createNewBot__wrapperInput}>
                                <Field
                                    disabled={
                                        (value === 'minPrice' && priceFromSource) ||
                                        (value === 'maxPrice' && priceFromSource)
                                    }
                                    id={value}
                                    type={type}
                                    placeholder={t('generals.typeHere')}
                                    name={value}
                                    value={createNewBot[value]}
                                    error={errors[error]}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t(`${label}`)}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                        );
                    })}
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            className={style.antCheckboxSourceVolume}
                            checked={priceFromSource}
                            onChange={switchSourceVolume}
                        >
                            {t('generals.priceFromSource')}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <GreenRedButtonBlock
                greenRedButtonWrapper={style.greenRedButtonWrapper}
                clickGreenBtn={submitcreateNewBot}
                clickRedBtn={cancelCreateBot}
                greenBtnText={t('generals.createNew')}
                redBtnText={t('generals.cancel')}
                disabled={createStrategyBotLoading}
            />
        </form>
    );
};

CreateStrategyBotView.defaultProps = {
    t: () => {},
    inputOnchange: () => {},
    selectOnChange: () => {},
    cancelCreateBot: () => {},
    submitcreateNewBot: () => {},
    switchSourceVolume: () => {},
    errors: {},
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    createNewBot: {},
    createStrategyBotLoading: false,
};

CreateStrategyBotView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    cancelCreateBot: PropTypes.func,
    submitcreateNewBot: PropTypes.func,
    switchSourceVolume: PropTypes.func,
    errors: PropTypes.object,
    allSources: PropTypes.arrayOf(PropTypes.object),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    createNewBot: PropTypes.object,
    createStrategyBotLoading: PropTypes.bool,
};

export default withTranslation()(CreateStrategyBotView);
