import { deleteBotConstants } from '../constants';

const deleteGeneratorBot = (state, action) => {
    if (state === undefined) {
        return {
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case deleteBotConstants.FETCH_DELETE_BOT_REQUEST:
            return {
                loading: true,
                error: null,
                success: false,
            };

        case deleteBotConstants.FETCH_DELETE_BOT_SUCCESS:
            return {
                loading: false,
                error: null,
                success: true,
            };

        case deleteBotConstants.FETCH_DELETE_BOT_FAILURE:
            return {
                loading: false,
                error: true,
                success: false,
            };

        default:
            return state;
    }
};

export default deleteGeneratorBot;
