import { createBot } from '../constants';

const createGeneratorBot = (state, action) => {
    if (state === undefined) {
        return {
            createProviderBot: {},
            loading: false,
            error: null,
        };
    }

    switch (action.type) {
        case createBot.FETCH_CREATE_BOT_REQUEST:
            return {
                createProviderBot: {},
                loading: true,
                error: null,
            };

        case createBot.FETCH_CREATE_BOT_SUCCESS:
            return {
                createProviderBot: action.payload,
                loading: false,
                error: null,
            };

        case createBot.FETCH_CREATE_BOT_FAILURE:
            return {
                createProviderBot: {},
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default createGeneratorBot;
