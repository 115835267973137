import { AVAILABLE_PAIRS_CONSTANTS } from '../constants';

const availableNewPairsRequested = () => ({
    type: AVAILABLE_PAIRS_CONSTANTS.GET_AVAILABLE_PAIRS_REQUEST,
});

const availableNewPairsLoaded = data => ({
    type: AVAILABLE_PAIRS_CONSTANTS.GET_AVAILABLE_PAIRS_SUCCESS,
    payload: data,
});

const availableNewPairsError = error => ({
    type: AVAILABLE_PAIRS_CONSTANTS.GET_AVAILABLE_PAIRS_FAILURE,
    payload: error,
});

const getAvailableNewPairsAction = getService => () => dispatch => {
    dispatch(availableNewPairsRequested());
    getService
        .getAvailableNewPairs()
        .then(data => {
            dispatch(availableNewPairsLoaded(data));
        })
        .catch(err => {
            dispatch(availableNewPairsError(err));
        });
};

export default getAvailableNewPairsAction;
