import { message } from 'antd';
import { deleteBotConstants } from '../constants';

const deleteBotRequested = () => ({
    type: deleteBotConstants.FETCH_DELETE_BOT_REQUEST,
});

const deleteBotLoaded = data => ({
    type: deleteBotConstants.FETCH_DELETE_BOT_SUCCESS,
    payload: data,
});

const deleteBotError = error => ({
    type: deleteBotConstants.FETCH_DELETE_BOT_FAILURE,
    payload: error,
});

const deleteProviderBotAction = deleteService => (deleteBtnId, t) => dispatch => {
    dispatch(deleteBotRequested());
    deleteService
        .deleteProviderBot(deleteBtnId)
        .then(data => {
            dispatch(deleteBotLoaded(data));
            message.success(t('createBot.botDeleted'), 2);
        })
        .catch(err => {
            dispatch(deleteBotError(err));
        });
};

export default deleteProviderBotAction;
