import { message } from 'antd';
import { CANCEL_BOT_ORDERS } from '../constants';

const cancelBotOrdersRequested = () => ({
    type: CANCEL_BOT_ORDERS.FETCH_CANCEL_BOT_ORDERS_REQUEST,
});

const cancelBotOrdersLoaded = data => ({
    type: CANCEL_BOT_ORDERS.FETCH_CANCEL_BOT_ORDERS_SUCCESS,
    payload: data,
});

const cancelBotOrdersError = error => ({
    type: CANCEL_BOT_ORDERS.FETCH_CANCEL_BOT_ORDERS_FAILURE,
    payload: error,
});

const cancelBotOrdersAction = deleteService => (pairname, t) => dispatch => {
    dispatch(cancelBotOrdersRequested());
    deleteService
        .cancelBotOrders(pairname)
        .then(item => {
            dispatch(cancelBotOrdersLoaded(item));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(cancelBotOrdersError(err));
            message.error(err.response.data.message, 2);
        });
};

export default cancelBotOrdersAction;
