import { message } from 'antd';
import { depthBotsPath, editDepthBotConstants, personalAreaPath } from '../constants';

const editDepthBotRequested = () => ({
    type: editDepthBotConstants.EDIT_DEPTH_BOT_REQUEST,
});

const editDepthBotLoaded = data => ({
    type: editDepthBotConstants.EDIT_DEPTH_BOT_SUCCESS,
    payload: data,
});

const editDepthBotError = error => ({
    type: editDepthBotConstants.EDIT_DEPTH_BOT_FAILURE,
    payload: error,
});

const editDepthBotAction = postService => (filterBotsdata, id, t, history) => dispatch => {
    dispatch(editDepthBotRequested());
    postService
        .updateDepthBot(filterBotsdata, id)
        .then(data => {
            dispatch(editDepthBotLoaded(data));
            message.success(t('createBot.updatedSuccessfully'), 2);
            history.push(`${personalAreaPath}${depthBotsPath}`);
        })
        .catch(err => {
            dispatch(editDepthBotError(err));
        });
};

export default editDepthBotAction;
