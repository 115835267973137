import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';
import SelectLanguage from '../../../../language';
import withGetService from '../../../../hoc/with-get-service';
import fetchProviderBotsAction from '../../../../../actions/getProviderBot.actions';
import { userActions } from '../../../../../actions';
import { compose } from '../../../../../utils';
import {
    personalAreaPath,
    providerBotsPath,
    providerBotsCreatePath,
    providerBotsStatistics,
    ordersPath,
    strategyBotsPath,
    strategyBotsCreatePath,
    depthBotsPath,
    depthBotsCreatePath,
} from '../../../../../constants';

import style from './aside.module.scss';
import './aside.scss';

const { SubMenu } = Menu;
class Aside extends Component {
    componentDidMount() {
        const { fetchProviderBots } = this.props;
        fetchProviderBots();
    }

    render() {
        const {
            t,
            location: { pathname },
            provederBots,
            logOut,
        } = this.props;
        let submenu = 'provider';
        let ordersStyle = 'menuItem';

        if (
            pathname === `${personalAreaPath}${providerBotsPath}` ||
            pathname === `${personalAreaPath}${providerBotsCreatePath}`
        ) {
            submenu = 'provider';
        }

        if (
            pathname === `${personalAreaPath}${strategyBotsPath}` ||
            pathname === `${personalAreaPath}${strategyBotsCreatePath}`
        ) {
            submenu = 'strategy';
        }

        if (
            pathname === `${personalAreaPath}${depthBotsPath}` ||
            pathname === `${personalAreaPath}${depthBotsCreatePath}`
        ) {
            submenu = 'strategy';
        }

        if (`/${pathname.split('/')[2]}` === `${ordersPath}` || pathname === `${personalAreaPath}${ordersPath}`) {
            submenu = 'orders';
            ordersStyle = 'menuItem ant-menu-item-selected';
        }

        return (
            <aside id="aside" className={style.aside}>
                <Menu defaultOpenKeys={[submenu]} mode="inline">
                    <SubMenu
                        key="provider"
                        title={
                            <span>
                                <Icon type="bar-chart" />
                                <span>{t('aside.liquidityProvider')}</span>
                            </span>
                        }
                    >
                        <Menu.Item key="1">
                            <NavLink to={`${personalAreaPath}${providerBotsPath}`} className="asideLink">
                                {t('aside.bots')}
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <NavLink to={`${personalAreaPath}${providerBotsCreatePath}`} className="asideLink">
                                {t('aside.createNewBot')}
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="statistics"
                        title={
                            <span>
                                <Icon type="line-chart" />
                                <span>{t('generals.statistics')}</span>
                            </span>
                        }
                    >
                        <SubMenu key="statisticsSub" title={t('aside.providerStatistics')}>
                            {provederBots &&
                                provederBots.map(item => {
                                    const { id } = item;
                                    return (
                                        <Menu.Item key={`statistics${id}`}>
                                            <NavLink
                                                to={`${personalAreaPath}${providerBotsStatistics}/${id}`}
                                                className="asideLink"
                                            >
                                                {id}
                                            </NavLink>
                                        </Menu.Item>
                                    );
                                })}
                        </SubMenu>
                    </SubMenu>
                    <Menu.Item key="orders" className={ordersStyle}>
                        <Link className="menuLink" to={`${personalAreaPath}${ordersPath}`}>
                            <Icon type="robot" />
                            {t('orders.title')}
                        </Link>
                    </Menu.Item>
                    <SubMenu
                        key="strategy"
                        title={
                            <span>
                                <Icon type="bar-chart" />
                                <span>{t('aside.strategy')}</span>
                            </span>
                        }
                    >
                        <Menu.Item key="3">
                            <NavLink to={`${personalAreaPath}${strategyBotsPath}`} className="asideLink">
                                {t('aside.volumeBots')}
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="4">
                            <NavLink to={`${personalAreaPath}${depthBotsPath}`} className="asideLink">
                                {t('aside.depthBots')}
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
                <ul className={style.aside__nav}>
                    <li>
                        <Link to="./" onClick={() => logOut()}>
                            <Icon type="logout" />
                            {t('aside.logout')}
                        </Link>
                    </li>
                </ul>
                <SelectLanguage />
            </aside>
        );
    }
}

Aside.defaultProps = {
    t: () => {},
    fetchProviderBots: () => {},
    logOut: () => {},
    location: {},
    provederBots: [],
};

Aside.propTypes = {
    t: PropTypes.func,
    fetchProviderBots: PropTypes.func,
    logOut: PropTypes.func,
    location: PropTypes.object,
    provederBots: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
        getProviderBot: { provederBots },
    } = state;
    return {
        loggedIn,
        provederBots,
    };
};

const mapDispatchToProps = (dispatch, { getService }) =>
    bindActionCreators(
        {
            fetchProviderBots: fetchProviderBotsAction(getService),
            logOut: () => userActions.logout(),
        },
        dispatch,
    );

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Aside);
