import { editDepthBotConstants } from '../constants';

const editDepthBot = (state, action) => {
    if (state === undefined) {
        return {
            editDepthBot: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case editDepthBotConstants.EDIT_DEPTH_BOT_REQUEST:
            return {
                editDepthBot: {},
                loading: true,
                error: null,
                success: false,
            };

        case editDepthBotConstants.EDIT_DEPTH_BOT_SUCCESS:
            return {
                editDepthBot: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case editDepthBotConstants.EDIT_DEPTH_BOT_FAILURE:
            return {
                editDepthBot: {},
                loading: false,
                error: action.payload,
                success: false,
            };

        default:
            return state;
    }
};

export default editDepthBot;
