import { message } from 'antd';
import { DELETE_ORDER } from '../constants';

const deletOrderRequested = () => ({
    type: DELETE_ORDER.FETCH_DELETE_ORDER_REQUEST,
});

const deletOrderLoaded = data => ({
    type: DELETE_ORDER.FETCH_DELETE_ORDER_SUCCESS,
    payload: data,
});

const deletOrderError = error => ({
    type: DELETE_ORDER.FETCH_DELETE_ORDER_FAILURE,
    payload: error,
});

const deletOrderAction = deleteService => (pairname, t) => dispatch => {
    dispatch(deletOrderRequested());
    deleteService
        .deleteOrder(pairname)
        .then(item => {
            dispatch(deletOrderLoaded(item));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(deletOrderError(err));
            message.error(err.response.data.message, 2);
        });
};

export default deletOrderAction;
