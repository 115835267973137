import { sentVolumeBuy } from '../constants';

const getSentVolumeBuyRequested = () => ({
    type: sentVolumeBuy.GET_SENT_VOLUME_BUY_REQUEST,
});

const getSentVolumeBuyLoaded = data => ({
    type: sentVolumeBuy.GET_SENT_VOLUME_BUY_SUCCESS,
    payload: data,
});

const getSentVolumeBuyError = error => ({
    type: sentVolumeBuy.GET_SENT_VOLUME_BUY_FAILURE,
    payload: error,
});

const getSentVolumeBuyAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getSentVolumeBuyRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getSentVolumeBuyLoaded(data));
        })
        .catch(err => {
            dispatch(getSentVolumeBuyError(err));
        });
};

export default getSentVolumeBuyAction;
