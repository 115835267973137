import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Select, Icon } from 'antd';
import classNames from 'classnames';

import Button from '../../../../../UI/button';
import Field from '../../../../../UI/field';
import style from './manual-orders.module.scss';

const ManualOrdersView = ({
    t,
    inputOnchange,
    selectOnChange,
    manualOrdersData,
    makePair,
    deleteRow,
    addRow,
    makeAll,
    cancelAll,
    rowCount,
    state,
    cancel,
    loadingMakeOrder,
    getOneOrderData: { orders: ordersList },
}) => {
    const { Option } = Select;

    const inputWrapperStyles = classNames(style.orders__wrapperInput, style.orders__wrapperInput_disabled);

    return (
        <div className={style.orders}>
            <div className={style.orders__wrapper}>
                <Fragment>
                    {manualOrdersData.map(orderItem => {
                        const { amount, id: orderId, price, side, pair } = orderItem;
                        const { left, right } = pair;

                        return (
                            <div key={orderId} className={style.orderListWrapper}>
                                <div className={style.orderListWrapper__fields}>
                                    <div className={inputWrapperStyles}>
                                        <Field
                                            id={`side${orderId}`}
                                            type="text"
                                            value={side}
                                            inputStyle={style.orders__wrapper_input}
                                            labelText={t('general.type')}
                                            labelStyle={style.orders__wrapper_label}
                                            disabled
                                        />
                                    </div>
                                    <div className={inputWrapperStyles}>
                                        <Field
                                            id={`amount${orderId}`}
                                            type="text"
                                            value={amount}
                                            inputStyle={style.orders__wrapper_input}
                                            labelText={t('general.amount')}
                                            labelStyle={style.orders__wrapper_label}
                                            disabled
                                        />
                                    </div>
                                    <div className={inputWrapperStyles}>
                                        <Field
                                            id={`price${orderId}`}
                                            type="text"
                                            value={price}
                                            inputStyle={style.orders__wrapper_input}
                                            labelText={t('general.price')}
                                            labelStyle={style.orders__wrapper_label}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <Button
                                    id="cancel"
                                    type="button"
                                    className={style.orders__wrapper_cancelButton}
                                    onClick={() => cancel(`${left}-${right}`, orderId)}
                                >
                                    {t('generals.cancel')}
                                </Button>
                            </div>
                        );
                    })}
                    {rowCount.map(id => {
                        const sideError = state[`error${id}`] ? state[`error${id}`].sideError : null;
                        const amountError = state[`error${id}`] ? state[`error${id}`].amountError : null;
                        const priceError = state[`error${id}`] ? state[`error${id}`].priceError : null;
                        return (
                            <div key={id} className={style.orders__wrapper_row}>
                                <div className={style.orders__wrapperInput}>
                                    <p className={style.orders__wrapper_title}>{t('general.type')}</p>
                                    <Select
                                        value={state[id] ? state[id].side : '' || undefined}
                                        className="antDSelect"
                                        placeholder={t('generals.choose')}
                                        onChange={(value, name) => selectOnChange(value, name, id)}
                                    >
                                        <Option name="side" value="BUY">
                                            {t('generals.buy')}
                                        </Option>
                                        <Option name="side" value="SELL">
                                            {t('generals.sell')}
                                        </Option>
                                    </Select>
                                    {sideError ? (
                                        <div className={style.orders__wrapperInput_invalid}>{sideError}</div>
                                    ) : null}
                                </div>
                                <div className={style.orders__wrapperInput}>
                                    <Field
                                        id={`amount${id}`}
                                        type="number"
                                        placeholder={t('generals.typeHere')}
                                        name="amount"
                                        value={state[id] ? state[id].amount : ''}
                                        error={amountError}
                                        onChange={event => inputOnchange(event, id)}
                                        inputStyle={style.orders__wrapper_input}
                                        labelText={t('general.amount')}
                                        labelStyle={style.orders__wrapper_label}
                                    />
                                </div>
                                <div className={style.orders__wrapperInput}>
                                    <Field
                                        id={`price${id}`}
                                        type="number"
                                        placeholder={t('generals.typeHere')}
                                        name="price"
                                        value={state[id] ? state[id].price : ''}
                                        error={priceError}
                                        onChange={event => inputOnchange(event, id)}
                                        inputStyle={style.orders__wrapper_input}
                                        labelText={t('general.price')}
                                        labelStyle={style.orders__wrapper_label}
                                    />
                                </div>
                                <Button
                                    id="makePair"
                                    type="button"
                                    disabled={loadingMakeOrder}
                                    className={style.orders__wrapper_makePairButton}
                                    onClick={() => makePair(id)}
                                >
                                    {t('generals.make')}
                                    <Icon
                                        className={
                                            loadingMakeOrder
                                                ? style.spinIcon
                                                : classNames(style.spinIcon, style.opacityZero)
                                        }
                                        type="sync"
                                        spin={loadingMakeOrder}
                                    />
                                </Button>
                                <Button
                                    id="cancelPair"
                                    type="button"
                                    className={style.orders__wrapper_cancelPairButton}
                                    onClick={() => deleteRow(id)}
                                >
                                    {t('generals.deleteRow')}
                                </Button>
                            </div>
                        );
                    })}
                    <div className={style.orders__wrapper_buttonBarWrapper}>
                        <Button
                            id="addRow"
                            type="button"
                            className={style.orders__wrapper_addRowButton}
                            onClick={addRow}
                        >
                            {t('generals.addRow')}
                        </Button>
                        {rowCount.length ? (
                            <Fragment>
                                <Button
                                    id="makeAll"
                                    type="button"
                                    className={style.orders__wrapper_makeAllButton}
                                    disabled={loadingMakeOrder}
                                    onClick={() => makeAll()}
                                >
                                    {t('generals.makeAll')}
                                    <Icon
                                        className={
                                            loadingMakeOrder
                                                ? style.spinIcon
                                                : classNames(style.spinIcon, style.opacityZero)
                                        }
                                        type="sync"
                                        spin={loadingMakeOrder}
                                    />
                                </Button>
                                {ordersList && ordersList.length ? (
                                    <Button
                                        id="cancelAll"
                                        type="button"
                                        className={style.orders__wrapper_cancelAllButton}
                                        onClick={() => cancelAll()}
                                    >
                                        {t('generals.cancelAll')}
                                    </Button>
                                ) : null}
                            </Fragment>
                        ) : null}
                    </div>
                </Fragment>
            </div>
        </div>
    );
};

ManualOrdersView.defaultProps = {
    t: () => {},
    inputOnchange: () => {},
    selectOnChange: () => {},
    makePair: () => {},
    deleteRow: () => {},
    addRow: () => {},
    makeAll: () => {},
    cancelAll: () => {},
    cancel: () => {},
    state: {},
    manualOrdersData: [],
    getOneOrderData: {},
    rowCount: 1,
    loadingMakeOrder: false,
};

ManualOrdersView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    makePair: PropTypes.func,
    deleteRow: PropTypes.func,
    addRow: PropTypes.func,
    makeAll: PropTypes.func,
    cancelAll: PropTypes.func,
    cancel: PropTypes.func,
    state: PropTypes.object,
    manualOrdersData: PropTypes.arrayOf(PropTypes.object),
    getOneOrderData: PropTypes.object,
    rowCount: PropTypes.any,
    loadingMakeOrder: PropTypes.bool,
};

export default withTranslation()(ManualOrdersView);
