import { accuracyPreparedVolumeBuy } from '../constants';

const getAccuracyPreparedVolumeBuyRequested = () => ({
    type: accuracyPreparedVolumeBuy.GET_ACCURACY_PREPEARED_VOLUME_BUY_REQUEST,
});

const getAccuracyPreparedVolumeBuyLoaded = data => ({
    type: accuracyPreparedVolumeBuy.GET_ACCURACY_PREPEARED_VOLUME_BUY_SUCCESS,
    payload: data,
});

const getAccuracyPreparedVolumeBuyError = error => ({
    type: accuracyPreparedVolumeBuy.GET_ACCURACY_PREPEARED_VOLUME_BUY_FAILURE,
    payload: error,
});

const accuracyPreparedVolumeBuyAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getAccuracyPreparedVolumeBuyRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getAccuracyPreparedVolumeBuyLoaded(data));
        })
        .catch(err => {
            dispatch(getAccuracyPreparedVolumeBuyError(err));
        });
};

export default accuracyPreparedVolumeBuyAction;
