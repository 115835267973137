import { getOrdersConstants } from '../constants';

const getOrdersRequested = () => ({
    type: getOrdersConstants.GET_ORDERS_REQUEST,
});

const getOrdersLoaded = data => ({
    type: getOrdersConstants.GET_ORDERS_SUCCESS,
    payload: data,
});

const getOrdersError = error => ({
    type: getOrdersConstants.GET_ORDERS_FAILURE,
    payload: error,
});

const fetchOrdersAction = getService => () => dispatch => {
    dispatch(getOrdersRequested());
    getService
        .getOrders()
        .then(data => {
            dispatch(getOrdersLoaded(data));
        })
        .catch(err => {
            dispatch(getOrdersError(err));
        });
};

export default fetchOrdersAction;
