import { message } from 'antd';
import { createDepthBotConstant, personalAreaPath, depthBotsPath } from '../constants';

const createDepthBotRequested = () => ({
    type: createDepthBotConstant.FETCH_CREATE_DEPTH_BOT_REQUEST,
});

const createDepthBotLoaded = data => ({
    type: createDepthBotConstant.FETCH_CREATE_DEPTH_BOT_SUCCESS,
    payload: data,
});

const createDepthBotError = error => ({
    type: createDepthBotConstant.FETCH_CREATE_DEPTH_BOT_FAILURE,
    payload: error,
});

const fetchCreateDepthBotAction = postService => (createNewBot, t, history) => dispatch => {
    dispatch(createDepthBotRequested());
    postService
        .createBotDepth(createNewBot)
        .then(data => {
            dispatch(createDepthBotLoaded(data));
            message.success(t('createBot.successCreated'), 2);
            history.push(`${personalAreaPath}${depthBotsPath}`);
        })
        .catch(err => {
            dispatch(createDepthBotError(err));
            message.error(err.message, 2);
        });
};

export default fetchCreateDepthBotAction;
