import { message } from 'antd';
import { deleteDepthBotConstants } from '../constants';

const deleteDepthBotRequested = () => ({
    type: deleteDepthBotConstants.FETCH_DELETE_DEPTH_BOT_REQUEST,
});

const deleteDepthBotLoaded = data => ({
    type: deleteDepthBotConstants.FETCH_DELETE_DEPTH_BOT_SUCCESS,
    payload: data,
});

const deleteDepthBotError = error => ({
    type: deleteDepthBotConstants.FETCH_DELETE_DEPTH_BOT_FAILURE,
    payload: error,
});

const deleteDepthBotAction = deleteService => (deleteBtnId, t) => dispatch => {
    dispatch(deleteDepthBotRequested());
    deleteService
        .deleteDepthBot(deleteBtnId)
        .then(data => {
            dispatch(deleteDepthBotLoaded(data));
            message.success(t('createBot.botDeleted'), 2);
        })
        .catch(err => {
            dispatch(deleteDepthBotError(err));
        });
};

export default deleteDepthBotAction;
