import { lifeCycleTimeLine } from '../constants';

const getLifeCycleTimeLineRequested = () => ({
    type: lifeCycleTimeLine.LIFE_CYCLE_TIME_LINE_REQUEST,
});

const getLifeCycleTimeLineLoaded = data => ({
    type: lifeCycleTimeLine.LIFE_CYCLE_TIME_LINE_SUCCESS,
    payload: data,
});

const getLifeCycleTimeLineError = error => ({
    type: lifeCycleTimeLine.LIFE_CYCLE_TIME_LINE_FAILURE,
    payload: error,
});

const lifeCycleTimeLineAction = getService => (pairName, chartType) => dispatch => {
    dispatch(getLifeCycleTimeLineRequested());
    getService
        .getLifeCycleTimeLine(pairName, chartType)
        .then(data => {
            dispatch(getLifeCycleTimeLineLoaded(data));
        })
        .catch(err => {
            dispatch(getLifeCycleTimeLineError(err));
        });
};

export default lifeCycleTimeLineAction;
