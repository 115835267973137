import { sentVolumeSell } from '../constants';

const getsentVolumeSellRequested = () => ({
    type: sentVolumeSell.GET_SENT_VOLUME_SELL_REQUEST,
});

const getsentVolumeSellLoaded = data => ({
    type: sentVolumeSell.GET_SENT_VOLUME_SELL_SUCCESS,
    payload: data,
});

const getsentVolumeSellError = error => ({
    type: sentVolumeSell.GET_SENT_VOLUME_SELL_FAILURE,
    payload: error,
});

const getSentVolumeSellAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getsentVolumeSellRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getsentVolumeSellLoaded(data));
        })
        .catch(err => {
            dispatch(getsentVolumeSellError(err));
        });
};

export default getSentVolumeSellAction;
