import { getCommonPairsConstants } from '../constants';

const getCommonPairsRequested = () => ({
    type: getCommonPairsConstants.GET_COMMON_PAIRS_REQUEST,
});

const getCommonPairsLoaded = data => ({
    type: getCommonPairsConstants.GET_COMMON_PAIRS_SUCCESS,
    payload: data,
});

const getCommonPairsError = error => ({
    type: getCommonPairsConstants.GET_COMMON_PAIRS_FAILURE,
    payload: error,
});

const fetchCommonPairsAction = getService => source => dispatch => {
    dispatch(getCommonPairsRequested());
    getService
        .getCommonPairs(source)
        .then(data => {
            dispatch(getCommonPairsLoaded(data));
        })
        .catch(err => {
            dispatch(getCommonPairsError(err));
        });
};

export default fetchCommonPairsAction;
