import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { message } from 'antd';

import withGetService from '../../../../hoc/with-get-service';
import withPatchService from '../../../../hoc/with-patch-service';
import withDeleteService from '../../../../hoc/with-delete-service';
import fetchStrategyBotsAction from '../../../../../actions/getStrategy.actions';
import deleteStrategyBotActions from '../../../../../actions/deleteStrategyBot.actions';
import switchStatusStrategyAction from '../../../../../actions/switchStatusStrategy.actions';
import StrategyBotView from './strategy-bot-view';
import { compose } from '../../../../../utils';
import Spinner from '../../../../spinner';
import {
    personalAreaPath,
    STATUS_BOT_RUNNING,
    STATUS_BOT_STOPPED,
    strategyBotsCreatePath,
    strategyBotsEditPath,
} from '../../../../../constants';
import stopAllBotsStrategyAction from '../../../../../actions/stopAllBotsStrategy.action';

export class StrategyBotContainer extends Component {
    static defaultProps = {
        t: () => {},
        history: {},
        switchStatus: false,
        successDeleteBot: false,
        successStopAllStrategyBots: false,
        strategyBots: [],
        loading: true,
        error: false,
        disabledStartAllBtn: false,
        fetchStrategyBots: () => {},
        fetchDeleteStrategyBot: () => {},
        fetchswitchStatusStrategy: () => {},
        fetchStopAllBotsStrategy: () => {},
    };

    static propTypes = {
        t: PropTypes.func,
        history: PropTypes.object,
        strategyBots: PropTypes.arrayOf(PropTypes.object),
        switchStatus: PropTypes.bool,
        successDeleteBot: PropTypes.bool,
        successStopAllStrategyBots: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.bool,
        disabledStartAllBtn: PropTypes.bool,
        fetchStrategyBots: PropTypes.func,
        fetchDeleteStrategyBot: PropTypes.func,
        fetchswitchStatusStrategy: PropTypes.func,
        fetchStopAllBotsStrategy: PropTypes.func,
    };

    state = {
        areUSureModal: false,
        deleteBtnId: null,
        filterBotsData: null,
        yesBtnAreUSureModalItem: '',
    };

    componentDidMount() {
        const { fetchStrategyBots } = this.props;
        fetchStrategyBots();
    }

    componentDidUpdate(prevProps) {
        const { fetchStrategyBots, switchStatus, successDeleteBot, successStopAllStrategyBots } = this.props;
        if (
            switchStatus !== prevProps.switchStatus ||
            successDeleteBot !== prevProps.successDeleteBot ||
            successStopAllStrategyBots !== prevProps.successStopAllStrategyBots
        ) {
            fetchStrategyBots();
        }
    }

    createNewBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${strategyBotsCreatePath}`);
    };

    stopAllBots = () => {
        const { areUSureModal } = this.state;
        this.setState({
            yesBtnAreUSureModalItem: 'stopAllBots',
            areUSureModal: !areUSureModal,
        });
    };

    startAllBots = () => {
        const { areUSureModal } = this.state;
        this.setState({
            yesBtnAreUSureModalItem: 'startAllBots',
            areUSureModal: !areUSureModal,
        });
    };

    switchAreUSureModal = () => {
        const { areUSureModal } = this.state;
        this.setState({
            areUSureModal: !areUSureModal,
        });
    };

    yesBtnAreUSureModal = () => {
        const { fetchDeleteStrategyBot, fetchswitchStatusStrategy, fetchStopAllBotsStrategy, t } = this.props;
        const { deleteBtnId, filterBotsData, yesBtnAreUSureModalItem } = this.state;
        this.setState({
            areUSureModal: false,
        });

        const stopData = {
            status: 'STOPPED',
        };

        const startData = {
            status: 'RUNNING',
        };

        if (yesBtnAreUSureModalItem === 'deleteStrategyBot') {
            fetchDeleteStrategyBot(deleteBtnId, t);
        }
        if (yesBtnAreUSureModalItem === 'switchStatusStrategy') {
            fetchswitchStatusStrategy(filterBotsData, filterBotsData.id, t);
        }
        if (yesBtnAreUSureModalItem === 'stopAllBots') {
            fetchStopAllBotsStrategy(stopData, t);
        }

        if (yesBtnAreUSureModalItem === 'startAllBots') {
            fetchStopAllBotsStrategy(startData, t);
        }
    };

    switchStatusStrategy = id => {
        const { strategyBots } = this.props;
        const filterBotsData = strategyBots.filter(item => item.id === id);
        const filterBotsDataObject = Object.assign({}, filterBotsData[0]);
        const currentStatus =
            filterBotsDataObject.status === STATUS_BOT_RUNNING ? STATUS_BOT_STOPPED : STATUS_BOT_RUNNING;

        this.setState(
            {
                yesBtnAreUSureModalItem: 'switchStatusStrategy',
                filterBotsData: {
                    ...filterBotsDataObject,
                    status: currentStatus,
                },
            },
            () => {
                this.switchAreUSureModal();
            },
        );
    };

    deleteStrategyBot = id => {
        this.switchAreUSureModal();
        this.setState({
            deleteBtnId: id,
            yesBtnAreUSureModalItem: 'deleteStrategyBot',
        });
    };

    editStrategyBot = id => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${strategyBotsEditPath}/${id}`);
    };

    cannotChahgeBot = () => {
        const { t } = this.props;
        message.error(t('createBot.NeedTurnOffBot'), 2);
    };

    render() {
        const { areUSureModal } = this.state;
        const { strategyBots, loading, error, disabledStartAllBtn } = this.props;

        const hasData = !(loading || error);

        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <StrategyBotView
                botsData={strategyBots}
                areUSureModal={areUSureModal}
                disabledStartAllBtn={disabledStartAllBtn}
                stopAllBots={this.stopAllBots}
                startAllBots={this.startAllBots}
                createNewBot={this.createNewBot}
                yesBtnAreUSureModal={this.yesBtnAreUSureModal}
                switchAreUSureModal={this.switchAreUSureModal}
                switchStatusStrategy={this.switchStatusStrategy}
                deleteStrategyBot={this.deleteStrategyBot}
                editStrategyBot={this.editStrategyBot}
                cannotChahgeBot={this.cannotChahgeBot}
            />
        ) : null;

        return (
            <Fragment>
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        getStrategyBot: { strategyBots, loading, error },
        statusStrategy: { success: switchStatus },
        deleteStrategyBot: { success: successDeleteBot },
        stopAllStrategyBots: { success: successStopAllStrategyBots, loading: disabledStartAllBtn },
    } = state;
    return {
        strategyBots,
        loading,
        error,
        switchStatus,
        successDeleteBot,
        successStopAllStrategyBots,
        disabledStartAllBtn,
    };
};

const mapDispatchToProps = (dispatch, { getService, deleteService, patchService }) =>
    bindActionCreators(
        {
            fetchStrategyBots: fetchStrategyBotsAction(getService),
            fetchDeleteStrategyBot: deleteStrategyBotActions(deleteService),
            fetchswitchStatusStrategy: switchStatusStrategyAction(patchService),
            fetchStopAllBotsStrategy: stopAllBotsStrategyAction(patchService),
        },
        dispatch,
    );

export default compose(
    withTranslation(),
    withDeleteService(),
    withGetService(),
    withPatchService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(StrategyBotContainer);
