import { configuredVolume } from '../constants';

const getConfiguredVolumeRequested = () => ({
    type: configuredVolume.GET_CONFIGURED_VOLUME_REQUEST,
});

const getConfiguredVolumeLoaded = data => ({
    type: configuredVolume.GET_CONFIGURED_VOLUME_SUCCESS,
    payload: data,
});

const getConfiguredVolumeError = error => ({
    type: configuredVolume.CONFIGURED_VOLUME_FAILURE,
    payload: error,
});

const configuredVolumeAction = getService => (pairName, chartType) => dispatch => {
    dispatch(getConfiguredVolumeRequested());
    getService
        .getProviderConfiguredVolume(pairName, chartType)
        .then(data => {
            dispatch(getConfiguredVolumeLoaded(data));
        })
        .catch(err => {
            dispatch(getConfiguredVolumeError(err));
        });
};

export default configuredVolumeAction;
