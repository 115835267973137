const timeInputs = [
    {
        value: 'time00_00_01_00',
        label: '00:00 - 01:00',
        error: 'time00_00_01_00Error',
    },
    {
        value: 'time01_00_02_00',
        label: '01:00 - 02:00',
        error: 'time01_00_02_00Error',
    },
    {
        value: 'time02_00_03_00',
        label: '02:00 - 03:00',
        error: 'time02_00_03_00Error',
    },
    {
        value: 'time03_00_04_00',
        label: '03:00 - 04:00',
        error: 'time03_00_04_00Error',
    },
    {
        value: 'time04_00_05_00',
        label: '04:00 - 05:00',
        error: 'time04_00_05_00Error',
    },
    {
        value: 'time05_00_06_00',
        label: '05:00 - 06:00',
        error: 'time05_00_06_00Error',
    },
    {
        value: 'time06_00_07_00',
        label: '06:00 - 07:00',
        error: 'time06_00_07_00Error',
    },
    {
        value: 'time07_00_08_00',
        label: '07:00 - 08:00',
        error: 'time07_00_08_00Error',
    },
    {
        value: 'time08_00_09_00',
        label: '08:00 - 09:00',
        error: 'time08_00_09_00Error',
    },
    {
        value: 'time09_00_10_00',
        label: '09:00 - 10:00',
        error: 'time09_00_10_00Error',
    },
    {
        value: 'time10_00_11_00',
        label: '10:00 - 11:00',
        error: 'time10_00_11_00Error',
    },
    {
        value: 'time11_00_12_00',
        label: '11:00 - 12:00',
        error: 'time11_00_12_00Error',
    },
    {
        value: 'time12_00_13_00',
        label: '12:00 - 13:00',
        error: 'time12_00_13_00Error',
    },
    {
        value: 'time13_00_14_00',
        label: '13:00 - 14:00',
        error: 'time13_00_14_00Error',
    },
    {
        value: 'time14_00_15_00',
        label: '14:00 - 15:00',
        error: 'time14_00_15_00Error',
    },
    {
        value: 'time15_00_16_00',
        label: '15:00 - 16:00',
        error: 'time15_00_16_00Error',
    },
    {
        value: 'time16_00_17_00',
        label: '16:00 - 17:00',
        error: 'time16_00_17_00Error',
    },
    {
        value: 'time17_00_18_00',
        label: '17:00 - 18:00',
        error: 'time17_00_18_00Error',
    },
    {
        value: 'time18_00_19_00',
        label: '18:00 - 19:00',
        error: 'time18_00_19_00Error',
    },
    {
        value: 'time19_00_20_00',
        label: '19:00 - 20:00',
        error: 'time19_00_20_00Error',
    },
    {
        value: 'time20_00_21_00',
        label: '20:00 - 21:00',
        error: 'time20_00_21_00Error',
    },
    {
        value: 'time21_00_22_00',
        label: '21:00 - 22:00',
        error: 'time21_00_22_00Error',
    },
    {
        value: 'time22_00_23_00',
        label: '22:00 - 23:00',
        error: 'time22_00_23_00Error',
    },
    {
        value: 'time23_00_24_00',
        label: '23:00 - 24:00',
        error: 'time23_00_24_00Error',
    },
];

export default timeInputs;
