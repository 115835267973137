import { CANCEL_BOT_ORDERS } from '../constants';

const cancelBotOrders = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case CANCEL_BOT_ORDERS.FETCH_CANCEL_BOT_ORDERS_REQUEST:
            return {
                data: action.payload,
                loading: true,
                error: null,
                success: false,
            };

        case CANCEL_BOT_ORDERS.FETCH_CANCEL_BOT_ORDERS_SUCCESS:
            return {
                data: {},
                loading: false,
                error: null,
                success: true,
            };

        case CANCEL_BOT_ORDERS.FETCH_CANCEL_BOT_ORDERS_FAILURE:
            return {
                data: action.payload,
                loading: false,
                error: true,
                success: false,
            };

        default:
            return state;
    }
};

export default cancelBotOrders;
