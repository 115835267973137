import { createStrategyBotConstant } from '../constants';

const createStrategyBot = (state, action) => {
    if (state === undefined) {
        return {
            createStrategyBot: {},
            loading: false,
            error: null,
        };
    }

    switch (action.type) {
        case createStrategyBotConstant.FETCH_CREATE_STRATEGY_BOT_REQUEST:
            return {
                createStrategyBot: {},
                loading: true,
                error: null,
            };

        case createStrategyBotConstant.FETCH_CREATE_STRATEGY_BOT_SUCCESS:
            return {
                createStrategyBot: action.payload,
                loading: false,
                error: null,
            };

        case createStrategyBotConstant.FETCH_CREATE_STRATEGY_BOT_FAILURE:
            return {
                createStrategyBot: {},
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default createStrategyBot;
