import { accuracySentVolumeBuy } from '../constants';

const getAccuracySentVolumeBuyRequested = () => ({
    type: accuracySentVolumeBuy.GET_ACCURACY_SENT_VOLUME_BUY_REQUEST,
});

const getAccuracySentVolumeBuyLoaded = data => ({
    type: accuracySentVolumeBuy.GET_ACCURACY_SENT_VOLUME_BUY_SUCCESS,
    payload: data,
});

const getAccuracySentVolumeBuyError = error => ({
    type: accuracySentVolumeBuy.GET_ACCURACY_SENT_VOLUME_BUY_FAILURE,
    payload: error,
});

const accuracySentVolumeBuyAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getAccuracySentVolumeBuyRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getAccuracySentVolumeBuyLoaded(data));
        })
        .catch(err => {
            dispatch(getAccuracySentVolumeBuyError(err));
        });
};

export default accuracySentVolumeBuyAction;
