import { deleteDepthBotConstants } from '../constants';

const deleteDepthBot = (state, action) => {
    if (state === undefined) {
        return {
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case deleteDepthBotConstants.FETCH_DELETE_DEPTH_BOT_REQUEST:
            return {
                loading: true,
                error: null,
                success: false,
            };

        case deleteDepthBotConstants.FETCH_DELETE_DEPTH_BOT_SUCCESS:
            return {
                loading: false,
                error: null,
                success: true,
            };

        case deleteDepthBotConstants.FETCH_DELETE_DEPTH_BOT_FAILURE:
            return {
                loading: false,
                error: true,
                success: false,
            };

        default:
            return state;
    }
};

export default deleteDepthBot;
