import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/app';
import ErrorBoundry from './components/pages/error-page/error-boundry';
import Spinner from './components/spinner';
import DeleteService from './services/delete-service';
import GetService from './services/get-service';
import PostService from './services/post-service';
import PatchService from './services/patch-service';
import { DeleteServiceProvider } from './components/context/delete-service-context';
import { GetServiceProvider } from './components/context/get-service-context';
import { PostServiceProvider } from './components/context/post-service-context';
import { PatchServiceProvider } from './components/context/patch-service-context';
import store from './store';
import './i18n';

import './components/app/app.scss';

const deleteService = new DeleteService();

const getService = new GetService();

const postService = new PostService();

const patchService = new PatchService();

ReactDOM.render(
    <Provider store={store}>
        <DeleteServiceProvider value={deleteService}>
            <GetServiceProvider value={getService}>
                <PatchServiceProvider value={patchService}>
                    <PostServiceProvider value={postService}>
                        <Suspense fallback={<Spinner />}>
                            <ErrorBoundry>
                                <App />
                            </ErrorBoundry>
                        </Suspense>
                    </PostServiceProvider>
                </PatchServiceProvider>
            </GetServiceProvider>
        </DeleteServiceProvider>
    </Provider>,
    document.getElementById('root'),
);
