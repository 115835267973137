import { getDepthBotConstants } from '../constants';

const depthBotsRequested = () => ({
    type: getDepthBotConstants.GET_DEPTH_BOT_REQUEST,
});

const depthBotsLoaded = data => ({
    type: getDepthBotConstants.GET_DEPTH_BOT_SUCCESS,
    payload: data,
});

const depthBotsError = error => ({
    type: getDepthBotConstants.GET_DEPTH_BOT_FAILURE,
    payload: error,
});

const fetchDepthBotsAction = getService => () => dispatch => {
    dispatch(depthBotsRequested());
    getService
        .getDepth()
        .then(data => {
            dispatch(depthBotsLoaded(data));
        })
        .catch(err => {
            if (err?.response?.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem('updateTokenTime');
                document.location.reload(true);
            }
            dispatch(depthBotsError(err));
        });
};

export default fetchDepthBotsAction;
