import { message } from 'antd';
import { stopAllBots } from '../constants';

const stopAllBotsRequested = () => ({
    type: stopAllBots.STOP_ALL_REQUEST,
});

const stopAllBotsRequestedLoaded = data => ({
    type: stopAllBots.STOP_ALL_SUCCESS,
    payload: data,
});

const stopAllBotsRequestedError = error => ({
    type: stopAllBots.STOP_ALL_FAILURE,
    payload: error,
});

const stopAllBotsProviderAction = patchService => (body, t) => dispatch => {
    dispatch(stopAllBotsRequested());
    patchService
        .stopAllProvider(body)
        .then(data => {
            dispatch(stopAllBotsRequestedLoaded(data));
            if (body.status === 'RUNNING') {
                message.success(t('createBot.allBotsRunning'), 2);
            } else if (body.status === 'STOPPED') {
                message.success(t('createBot.allBotsStopped'), 2);
            }
        })
        .catch(err => {
            dispatch(stopAllBotsRequestedError(err));
        });
};

export default stopAllBotsProviderAction;
