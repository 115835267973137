import { prepearedVolumeBuy } from '../constants';

const getPrepearedVolumeBuyRequested = () => ({
    type: prepearedVolumeBuy.GET_PREPEARED_VOLUME_BUY_REQUEST,
});

const getPrepearedVolumeBuyLoaded = data => ({
    type: prepearedVolumeBuy.GET_PREPEARED_VOLUME_BUY_SUCCESS,
    payload: data,
});

const getPrepearedVolumeBuyError = error => ({
    type: prepearedVolumeBuy.GET_PREPEARED_VOLUME_BUY_FAILURE,
    payload: error,
});

const getPrepearedVolumeBuyAction = getService => (pairName, chartType, side) => dispatch => {
    dispatch(getPrepearedVolumeBuyRequested());
    getService
        .getProviderStatistics(pairName, chartType, side)
        .then(data => {
            dispatch(getPrepearedVolumeBuyLoaded(data));
        })
        .catch(err => {
            dispatch(getPrepearedVolumeBuyError(err));
        });
};

export default getPrepearedVolumeBuyAction;
