import { getAllCoinsConstants } from '../constants';

const getAllCoinsRequested = () => ({
    type: getAllCoinsConstants.GET_COINS_REQUEST,
});

const getAllCoinsLoaded = data => ({
    type: getAllCoinsConstants.GET_COINS_SUCCESS,
    payload: data,
});

const getAllCoinsError = error => ({
    type: getAllCoinsConstants.GET_COINS_FAILURE,
    payload: error,
});

const fetchGeneratorBotsAction = getService => source => dispatch => {
    dispatch(getAllCoinsRequested());
    getService
        .getAllCoins(source)
        .then(data => {
            dispatch(getAllCoinsLoaded(data));
        })
        .catch(err => {
            dispatch(getAllCoinsError(err));
        });
};

export default fetchGeneratorBotsAction;
