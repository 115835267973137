import { message } from 'antd';
import { editStrategyBotConstants, personalAreaPath, strategyBotsPath } from '../constants';

const editStrategyBotRequested = () => ({
    type: editStrategyBotConstants.EDIT_STRATEGY_BOT_REQUEST,
});

const editStrategyBotLoaded = data => ({
    type: editStrategyBotConstants.EDIT_STRATEGY_BOT_SUCCESS,
    payload: data,
});

const editStrategyBotError = error => ({
    type: editStrategyBotConstants.EDIT_STRATEGY_BOT_FAILURE,
    payload: error,
});

const editStrategyBotAction = postService => (filterBotsdata, id, t, history) => dispatch => {
    dispatch(editStrategyBotRequested());
    postService
        .updateStrategyBot(filterBotsdata, id)
        .then(data => {
            dispatch(editStrategyBotLoaded(data));
            message.success(t('createBot.updatedSuccessfully'), 2);
            history.push(`${personalAreaPath}${strategyBotsPath}`);
        })
        .catch(err => {
            dispatch(editStrategyBotError(err));
        });
};

export default editStrategyBotAction;
