import { getOneOrderConstants } from '../constants';

const getOneOrder = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: false,
            success: false,
        };
    }

    switch (action.type) {
        case getOneOrderConstants.GET_ONE_ORDER_REQUEST:
            return {
                data: {},
                loading: true,
                error: false,
                success: false,
            };

        case getOneOrderConstants.GET_ONE_ORDER_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: false,
                success: true,
            };

        case getOneOrderConstants.GET_ONE_ORDER_FAILURE:
            return {
                data: {},
                loading: false,
                error: true,
                success: false,
            };

        default:
            return state;
    }
};

export default getOneOrder;
