import { prepearedVolumeSell } from '../constants';

const getPrepearedVolume = (state, action) => {
    if (state === undefined) {
        return {
            getPrepearedVolume: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
        case prepearedVolumeSell.GET_PREPEARED_VOLUME_SELL_REQUEST:
            return {
                getPrepearedVolume: [],
                loading: true,
                error: false,
            };

        case prepearedVolumeSell.GET_PREPEARED_VOLUME_SELL_SUCCESS:
            return {
                getPrepearedVolume: action.payload,
                loading: false,
                error: false,
            };

        case prepearedVolumeSell.GET_PREPEARED_VOLUME_SELL_FAILURE:
            return {
                getPrepearedVolume: [],
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};

export default getPrepearedVolume;
