/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { message } from 'antd';

import withGetService from '../../../../hoc/with-get-service';
import withPatchService from '../../../../hoc/with-patch-service';
import withDeleteService from '../../../../hoc/with-delete-service';
import getPrepearedVolumeAction from '../../../../../actions/getPrepearedVolumeSell.actions';
import getPrepearedVolumeBuyAction from '../../../../../actions/getPrepearedVolumeBuy.action';
import getSentVolumeSellAction from '../../../../../actions/getSentVolumeSell.actions';
import getSentVolumeBuyAction from '../../../../../actions/getSentVolumeBuy.actions';
import accuracyPreparedVolumeBuyAction from '../../../../../actions/getAccuracyPreparedVolumeBuy.actions';
import accuracyPreparedVolumeSellAction from '../../../../../actions/getAccuracyPreparedVolumeSell.actions';
import accuracySentVolumeBuyAction from '../../../../../actions/getAccuracySentVolumeBuy.actions';
import accuracySentVolumeSellAction from '../../../../../actions/getAccuracySentVolumeSell.actions';
import configuredVolumeAction from '../../../../../actions/getConfiguredVolume.actions';
import lifeCycleTimeLineAction from '../../../../../actions/getLifeCycleTimeLine.actions';
import workLoadTimeLineAction from '../../../../../actions/getWorkLoadTimeLine.actions';

import ProviderStatisticsView from './provider-statistics-view';
import { compose } from '../../../../../utils';
import Spinner from '../../../../spinner';
import {
    personalAreaPath,
    providerBotsCreatePath,
    providerBotsEditPath,
    STATUS_BOT_RUNNING,
    STATUS_BOT_STOPPED,
} from '../../../../../constants';

export class ProviderStatisticsContainer extends Component {
    static defaultProps = {
        t: () => {},
        match: {},
        history: {},
        provederBots: [],
        loading: true,
        error: false,
        fetchDeleteProviderBot: () => {},
        fetchswitchStatusProvider: () => {},
        fetchStopAllBotsProvider: () => {},
        getPrepearedVolumeSell: () => {},
        getPrepearedVolumeBuy: () => {},
        getSentVolumeSell: () => {},
        getSentVolumeBuy: () => {},
        getAccuracyPreparedVolumeBuy: () => {},
        getAccuracyPreparedVolumeSell: () => {},
        getAccuracySentVolumeBuy: () => {},
        getAccuracySentVolumeSell: () => {},
        getConfiguredVolume: () => {},
        getWorkLoadTimeLine: () => {},
        getLifeCycleTimeLine: () => {},
        prepearedVolumeSell: [],
        prepearedVolumeBuy: [],
        sentVolumeSell: [],
        sentVolumeBuy: [],
        accuracyPreparedVolumeBuy: [],
        accuracyPreparedVolumeSell: [],
        accuracySentVolumeBuy: [],
        accuracySentVolumeSell: [],
        configuredVolume: [],
        workLoadTimeLine: [],
        lifeCycleTimeLine: [],
    };

    static propTypes = {
        t: PropTypes.func,
        match: PropTypes.object,
        history: PropTypes.object,
        provederBots: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.bool,
        fetchDeleteProviderBot: PropTypes.func,
        fetchswitchStatusProvider: PropTypes.func,
        fetchStopAllBotsProvider: PropTypes.func,
        getPrepearedVolumeSell: PropTypes.func,
        getPrepearedVolumeBuy: PropTypes.func,
        getSentVolumeSell: PropTypes.func,
        getSentVolumeBuy: PropTypes.func,
        getAccuracyPreparedVolumeBuy: PropTypes.func,
        getAccuracyPreparedVolumeSell: PropTypes.func,
        getAccuracySentVolumeBuy: PropTypes.func,
        getAccuracySentVolumeSell: PropTypes.func,
        getConfiguredVolume: PropTypes.func,
        getWorkLoadTimeLine: PropTypes.func,
        getLifeCycleTimeLine: PropTypes.func,
        prepearedVolumeSell: PropTypes.any,
        prepearedVolumeBuy: PropTypes.any,
        sentVolumeSell: PropTypes.any,
        sentVolumeBuy: PropTypes.any,
        accuracyPreparedVolumeBuy: PropTypes.any,
        accuracyPreparedVolumeSell: PropTypes.any,
        accuracySentVolumeBuy: PropTypes.any,
        accuracySentVolumeSell: PropTypes.any,
        configuredVolume: PropTypes.any,
        workLoadTimeLine: PropTypes.any,
        lifeCycleTimeLine: PropTypes.any,
    };

    state = {
        areUSureModal: false,
        deleteBtnId: null,
        filterBotsData: null,
        yesBtnAreUSureModalItem: '',
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const {
            match: {
                params: { id: botId },
            },
        } = this.props;

        if (botId !== prevProps.match.params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const {
            getPrepearedVolumeSell,
            getPrepearedVolumeBuy,
            getSentVolumeSell,
            getSentVolumeBuy,
            getAccuracyPreparedVolumeBuy,
            getAccuracyPreparedVolumeSell,
            getAccuracySentVolumeBuy,
            getAccuracySentVolumeSell,
            getConfiguredVolume,
            getWorkLoadTimeLine,
            getLifeCycleTimeLine,
            match: {
                params: { id: botId },
            },
        } = this.props;
        getPrepearedVolumeSell(botId, 'PREPARED_VOLUME', 'SELL');
        getPrepearedVolumeBuy(botId, 'PREPARED_VOLUME', 'BUY');
        getSentVolumeSell(botId, 'SENT_VOLUME', 'SELL');
        getSentVolumeBuy(botId, 'SENT_VOLUME', 'BUY');
        getAccuracyPreparedVolumeBuy(botId, 'ACCURACY_OF_PREPARED_VOLUME', 'BUY');
        getAccuracyPreparedVolumeSell(botId, 'ACCURACY_OF_PREPARED_VOLUME', 'SELL');
        getAccuracySentVolumeBuy(botId, 'ACCURACY_OF_SENT_VOLUME', 'BUY');
        getAccuracySentVolumeSell(botId, 'ACCURACY_OF_SENT_VOLUME', 'SELL');
        getConfiguredVolume(botId, 'CONFIGURED_VOLUME');
        getWorkLoadTimeLine(botId, 'WORKLOAD_TIMELINE');
        getLifeCycleTimeLine(botId, 'LIFECYCLE_TIMELINE');
    };

    createNewBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${providerBotsCreatePath}`);
    };

    stopAllBots = () => {
        const { areUSureModal } = this.state;
        this.setState({
            yesBtnAreUSureModalItem: 'stopAllBots',
            areUSureModal: !areUSureModal,
        });
    };

    switchAreUSureModal = () => {
        const { areUSureModal } = this.state;
        this.setState({
            areUSureModal: !areUSureModal,
        });
    };

    yesBtnAreUSureModal = () => {
        const { fetchDeleteProviderBot, fetchswitchStatusProvider, fetchStopAllBotsProvider, t } = this.props;
        const { deleteBtnId, filterBotsData, yesBtnAreUSureModalItem } = this.state;
        this.setState({
            areUSureModal: false,
        });

        const data = {
            status: 'STOPPED',
        };
        if (yesBtnAreUSureModalItem === 'deleteProviderBot') {
            fetchDeleteProviderBot(deleteBtnId, t);
        }
        if (yesBtnAreUSureModalItem === 'switchStatusProvider') {
            fetchswitchStatusProvider(filterBotsData, filterBotsData.id, t);
        }
        if (yesBtnAreUSureModalItem === 'stopAllBots') {
            fetchStopAllBotsProvider(data, t);
        }
    };

    switchStatusProvider = id => {
        const { provederBots } = this.props;
        const filterBotsData = provederBots.filter(item => item.id === id);
        const filterBotsDataObject = Object.assign({}, filterBotsData[0]);
        const currentStatus =
            filterBotsDataObject.status === STATUS_BOT_RUNNING ? STATUS_BOT_STOPPED : STATUS_BOT_RUNNING;

        this.setState(
            {
                yesBtnAreUSureModalItem: 'switchStatusProvider',
                filterBotsData: {
                    ...filterBotsDataObject,
                    status: currentStatus,
                },
            },
            () => {
                this.switchAreUSureModal();
            },
        );
    };

    deleteProviderBot = id => {
        this.switchAreUSureModal();
        this.setState({
            deleteBtnId: id,
            yesBtnAreUSureModalItem: 'deleteProviderBot',
        });
    };

    editProviderBot = id => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${providerBotsEditPath}/${id}`);
    };

    cannotChahgeBot = () => {
        const { t } = this.props;
        message.error(t('createBot.NeedTurnOffBot'), 2);
    };

    render() {
        const {
            loading,
            error,
            match,
            prepearedVolumeSell,
            prepearedVolumeBuy,
            sentVolumeSell,
            sentVolumeBuy,
            accuracyPreparedVolumeBuy,
            accuracyPreparedVolumeSell,
            accuracySentVolumeBuy,
            accuracySentVolumeSell,
            configuredVolume,
            workLoadTimeLine,
            lifeCycleTimeLine,
        } = this.props;
        const hasData = !(loading || error);
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <ProviderStatisticsView
                match={match}
                prepearedVolumeSell={prepearedVolumeSell}
                prepearedVolumeBuy={prepearedVolumeBuy}
                sentVolumeSell={sentVolumeSell}
                sentVolumeBuy={sentVolumeBuy}
                accuracyPreparedVolumeBuy={accuracyPreparedVolumeBuy}
                accuracyPreparedVolumeSell={accuracyPreparedVolumeSell}
                accuracySentVolumeBuy={accuracySentVolumeBuy}
                accuracySentVolumeSell={accuracySentVolumeSell}
                configuredVolume={configuredVolume}
                workLoadTimeLine={workLoadTimeLine}
                lifeCycleTimeLine={lifeCycleTimeLine}
            />
        ) : null;
        return (
            <Fragment>
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        getProviderBot: { provederBots, error },
        statusProvider: { success: switchStatus },
        deleteProviderBot: { success: successDeleteBot },
        stopAllProviderBots: { success: successStopAllProviderBots },
        getPrepearedVolume: {
            getPrepearedVolume: { data: prepearedVolumeSell },
        },
        getPrepearedVolumeBuy: {
            getPrepearedVolumeBuy: { data: prepearedVolumeBuy },
        },
        getSentVolumeSell: {
            getSentVolumeSell: { data: sentVolumeSell },
        },
        getSentVolumeBuy: {
            getSentVolumeBuy: { data: sentVolumeBuy },
            loading,
        },
        getAccuracyPreparedVolumeBuy: {
            accuracyPreparedVolumeBuy: { data: accuracyPreparedVolumeBuy },
        },
        getAccuracyPreparedVolumeSell: {
            accuracyPreparedVolumeSell: { data: accuracyPreparedVolumeSell },
        },
        getAccuracySentVolumeBuy: {
            accuracySentVolumeBuy: { data: accuracySentVolumeBuy },
        },
        getAccuracySentVolumeSell: {
            accuracySentVolumeSell: { data: accuracySentVolumeSell },
        },
        getConfiguredVolume: {
            configuredVolume: { data: configuredVolume },
        },
        getWorkLoadTimeLine: {
            workLoadTimeLine: { data: workLoadTimeLine },
        },
        getLifeCycleTimeLine: {
            lifeCycleTimeLine: { data: lifeCycleTimeLine },
        },
    } = state;

    return {
        provederBots,
        loading,
        error,
        switchStatus,
        successDeleteBot,
        successStopAllProviderBots,
        prepearedVolumeSell,
        prepearedVolumeBuy,
        sentVolumeSell,
        sentVolumeBuy,
        accuracyPreparedVolumeBuy,
        accuracyPreparedVolumeSell,
        accuracySentVolumeBuy,
        accuracySentVolumeSell,
        configuredVolume,
        workLoadTimeLine,
        lifeCycleTimeLine,
    };
};

const mapDispatchToProps = (dispatch, { getService }) =>
    bindActionCreators(
        {
            getPrepearedVolumeSell: getPrepearedVolumeAction(getService),
            getPrepearedVolumeBuy: getPrepearedVolumeBuyAction(getService),
            getSentVolumeSell: getSentVolumeSellAction(getService),
            getSentVolumeBuy: getSentVolumeBuyAction(getService),
            getAccuracyPreparedVolumeBuy: accuracyPreparedVolumeBuyAction(getService),
            getAccuracyPreparedVolumeSell: accuracyPreparedVolumeSellAction(getService),
            getAccuracySentVolumeBuy: accuracySentVolumeBuyAction(getService),
            getAccuracySentVolumeSell: accuracySentVolumeSellAction(getService),
            getConfiguredVolume: configuredVolumeAction(getService),
            getWorkLoadTimeLine: workLoadTimeLineAction(getService),
            getLifeCycleTimeLine: lifeCycleTimeLineAction(getService),
        },
        dispatch,
    );

export default compose(
    withTranslation(),
    withDeleteService(),
    withGetService(),
    withPatchService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(ProviderStatisticsContainer);
