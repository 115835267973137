import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { message } from 'antd';
import withPostService from '../../../../hoc/with-post-service';
import withGetService from '../../../../hoc/with-get-service';
import { compose } from '../../../../../utils';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import fetchAllCoinsAction from '../../../../../actions/getAllCoins.actions';
import fetchCurrenciesFiatAction from '../../../../../actions/getCurrenciesAll.actions';
import fetchCommonPairsAction from '../../../../../actions/getCommonPairs.actions';
import fetchTargetPairsAction from '../../../../../actions/getTargetPairs.actions';
import fetchAllSourcesAction from '../../../../../actions/getAllSources.actions';
import { personalAreaPath, depthBotsPath } from '../../../../../constants';
import CreateDepthBotView from './create-depth-bot-view';
import fetchCreateDepthBotAction from '../../../../../actions/postCreateDepthBot.actions';

export class CreateDepthBotContainer extends Component {
    state = {
        createNewBot: {
            depth: '',
            minOrderAmount: '',
            maxOrderAmount: '',
            minPercentBetweenOrdersPrice: '',
            maxPercentBetweenOrdersPrice: '',
            spreadPriceDistancePercent: '',
            refreshSeconds: '',
            id: '',
            userId: null,
            pair: {
                left: '',
                right: '',
            },
            priceSource: 'BINANCE',
            status: 'STOPPED',
            tradingOnTargetExchangeEnabled: false,
            sourceVolumeEnabled: false,
            sourceVolumePercent: '',
            minTimeVolumeTradeSeconds: '',
            maxTimeVolumeTradeSeconds: '',
            minAmountPerTrade: '',
            dailyVolume: '',
            maxDailyVolumeDeviationPercent: '',
        },
        errors: {},
        loading: false,
    };

    componentDidMount() {
        const { fetchAllCoins, fetchCommonPairs, fetchTargetPairs, fetchCurrenciesFiat, fetchAllSources } = this.props;
        fetchAllCoins();
        fetchCommonPairs();
        fetchTargetPairs();
        fetchCurrenciesFiat();
        fetchAllSources();
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchCommonPairs, fetchAllCoins } = this.props;
        const { createNewBot } = this.state;

        if (prevState.createNewBot.priceSource !== createNewBot.priceSource) {
            fetchCommonPairs(createNewBot.priceSource);
            fetchAllCoins(createNewBot.priceSource);
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                [event.target.name]: event.target.value,
            },
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        const { createNewBot } = this.state;

        if (name.props.name === 'id') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                },
            });
        }
    };

    // redirect to bots page
    cancelCreateBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${depthBotsPath}`);
    };

    switchTradingOnTargetExchangeEnabled = e => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                tradingOnTargetExchangeEnabled: !!e.target.checked,
            },
        });
    };

    switchSourceVolumeEnabled = e => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                sourceVolumeEnabled: !!e.target.checked,
            },
        });
    };

    // btn create bot
    submitcreateNewBot = event => {
        event.preventDefault();
        const { t, history, fetchCreateDepthBot } = this.props;
        const { createNewBot } = this.state;
        const errors = {};

        const {
            minOrderAmount,
            maxOrderAmount,
            depth,
            id,
            minPercentBetweenOrdersPrice,
            maxPercentBetweenOrdersPrice,
            spreadPriceDistancePercent,
            priceSource,
            refreshSeconds,
            userId,
            sourceVolumePercent,
            minTimeVolumeTradeSeconds,
            maxTimeVolumeTradeSeconds,
            minAmountPerTrade,
            dailyVolume,
            maxDailyVolumeDeviationPercent,
        } = createNewBot;

        this.setState({
            createNewBot: {
                ...createNewBot,
            },
        });

        if (userId?.length > 0) {
            if (!Number.isInteger(+userId)) {
                errors.userId = `${t('createBot.valueMustBeInteger')}`;
            }
            if (+userId < 0) {
                errors.userId = `${t('createBot.valueMustBePositive')}`;
            }

            if (userId.length < 1) {
                errors.userIdError = t('error.input', { count: 1 });
            }
        }

        if (!Number.isInteger(+depth)) {
            errors.depth = `${t('createBot.valueMustBeInteger')}`;
        }

        if (!Number.isInteger(+refreshSeconds)) {
            errors.refreshSeconds = `${t('createBot.valueMustBeInteger')}`;
        }
        if (+refreshSeconds < 1) {
            errors.refreshSeconds = `${t('generals.minValue')} 1`;
        }
        if (+depth < 1) {
            errors.depth = `${t('generals.minValue')} 1`;
        }

        if (+spreadPriceDistancePercent > 100) {
            errors.spreadPriceDistancePercent = `${t('createBot.maxValue')} 100`;
        }

        if (+spreadPriceDistancePercent <= 0) {
            errors.spreadPriceDistancePercent = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+maxPercentBetweenOrdersPrice <= 0) {
            errors.maxPercentBetweenOrdersPrice = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minPercentBetweenOrdersPrice <= 0) {
            errors.minPercentBetweenOrdersPrice = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+maxPercentBetweenOrdersPrice > 100) {
            errors.maxPercentBetweenOrdersPrice = `${t('createBot.maxValue')} 100`;
        }

        if (+minPercentBetweenOrdersPrice > 100) {
            errors.minPercentBetweenOrdersPrice = `${t('createBot.maxValue')} 100`;
        }

        if (+maxOrderAmount <= 0) {
            errors.maxOrderAmount = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minOrderAmount <= 0) {
            errors.minOrderAmount = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minOrderAmount >= +maxOrderAmount) {
            errors.minOrderAmount = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (+minPercentBetweenOrdersPrice >= +maxPercentBetweenOrdersPrice) {
            errors.minPercentBetweenOrdersPrice = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (id?.length < 1) {
            errors.pairNameError = t('general.selectFromTheList');
        }

        if (priceSource?.length < 1) {
            errors.sourceError = t('general.selectFromTheList');
        }

        if (sourceVolumePercent > 100) {
            errors.sourceVolumePercent = `${t('createBot.maxValue')} 100`;
        }

        if (sourceVolumePercent <= 0) {
            errors.sourceVolumePercent = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (minTimeVolumeTradeSeconds <= 0) {
            errors.minTimeVolumeTradeSeconds = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (maxTimeVolumeTradeSeconds <= 0) {
            errors.maxTimeVolumeTradeSeconds = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minTimeVolumeTradeSeconds >= +maxTimeVolumeTradeSeconds) {
            errors.minTimeVolumeTradeSeconds = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (minAmountPerTrade <= 0) {
            errors.minAmountPerTrade = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (dailyVolume <= 0) {
            errors.dailyVolume = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (maxDailyVolumeDeviationPercent > 100) {
            errors.maxDailyVolumeDeviationPercent = `${t('createBot.maxValue')} 100`;
        }

        if (maxDailyVolumeDeviationPercent <= 0) {
            errors.maxDailyVolumeDeviationPercent = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (Object.keys(errors).length > 0) {
            message.error(t('general.errorFields'), 2);
            return this.setState({
                errors,
            });
        }

        let request = {};
        Object.keys(createNewBot).forEach(key => {
            request = {
                ...request,
                [key]: createNewBot[key] === '' ? null : createNewBot[key],
                userId: createNewBot.userId?.length > 0 ? createNewBot.userId : null,
            };
        });
        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 2);
        } else {
            fetchCreateDepthBot(request, t, history);
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { createNewBot, loading, error, errors } = this.state;
        const { createDepthBotLoading, allSources, commonPairs, targetPairs } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <CreateDepthBotView
                createNewBot={createNewBot}
                errors={errors}
                commonPairs={commonPairs}
                targetPairs={targetPairs}
                allSources={allSources}
                createDepthBotLoading={createDepthBotLoading}
                inputOnchange={this.inputOnchange}
                selectOnChange={this.selectOnChange}
                cancelCreateBot={this.cancelCreateBot}
                submitcreateNewBot={this.submitcreateNewBot}
                switchTradingOnTargetExchangeEnabled={this.switchTradingOnTargetExchangeEnabled}
                switchSourceVolumeEnabled={this.switchSourceVolumeEnabled}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

CreateDepthBotContainer.defaultProps = {
    t: () => {},
    history: {},
    fetchCreateDepthBot: () => {},
    fetchAllCoins: () => {},
    fetchAllSources: () => {},
    fetchCurrenciesFiat: () => {},
    fetchCommonPairs: () => {},
    fetchTargetPairs: () => {},
    commonPairs: [],
    targetPairs: [],
    allSources: [],
    createDepthBotLoading: false,
};

CreateDepthBotContainer.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    fetchCreateDepthBot: PropTypes.func,
    fetchAllCoins: PropTypes.func,
    fetchAllSources: PropTypes.func,
    fetchCurrenciesFiat: PropTypes.func,
    fetchCommonPairs: PropTypes.func,
    fetchTargetPairs: PropTypes.func,
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    allSources: PropTypes.arrayOf(PropTypes.object),
    createDepthBotLoading: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        createDepthBot: { loading: createDepthBotLoading },
        allCoins: { allCoins },
        allSources: { allSources },
        commonPairs: { commonPairs },
        targetPairs: { targetPairs },
        currenciesAll: { data: currenciesAll },
    } = state;

    return {
        createDepthBotLoading,
        allCoins,
        allSources,
        commonPairs,
        targetPairs,
        currenciesAll,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) =>
    bindActionCreators(
        {
            fetchCreateDepthBot: fetchCreateDepthBotAction(postService),
            fetchAllCoins: fetchAllCoinsAction(getService),
            fetchCurrenciesFiat: fetchCurrenciesFiatAction(getService),
            fetchCommonPairs: fetchCommonPairsAction(getService),
            fetchTargetPairs: fetchTargetPairsAction(getService),
            fetchAllSources: fetchAllSourcesAction(getService),
        },
        dispatch,
    );

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(CreateDepthBotContainer);
