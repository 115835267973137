import { createDepthBotConstant } from '../constants';

const createDepthBot = (state, action) => {
    if (state === undefined) {
        return {
            createDepthBot: {},
            loading: false,
            error: null,
        };
    }

    switch (action.type) {
        case createDepthBotConstant.FETCH_CREATE_DEPTH_BOT_REQUEST:
            return {
                createDepthBot: {},
                loading: true,
                error: null,
            };

        case createDepthBotConstant.FETCH_CREATE_DEPTH_BOT_SUCCESS:
            return {
                createDepthBot: action.payload,
                loading: false,
                error: null,
            };

        case createDepthBotConstant.FETCH_CREATE_DEPTH_BOT_FAILURE:
            return {
                createDepthBot: {},
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default createDepthBot;
