import { stopAllBotsStrategyConstants } from '../constants';

const stopAllStrategyBots = (state, action) => {
    if (state === undefined) {
        return {
            stopAllBots: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
        case stopAllBotsStrategyConstants.STOP_ALL_STRATEGY_REQUEST:
            return {
                stopAllBots: {},
                loading: true,
                error: null,
                success: false,
            };

        case stopAllBotsStrategyConstants.STOP_ALL_STRATEGY_SUCCESS:
            return {
                stopAllBots: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case stopAllBotsStrategyConstants.STOP_ALL_STRATEGY_FAILURE:
            return {
                stopAllBots: {},
                loading: false,
                error: action.payload,
                success: false,
            };

        default:
            return state;
    }
};

export default stopAllStrategyBots;
