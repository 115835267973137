import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { message } from 'antd';
import withPostService from '../../../../hoc/with-post-service';
import withGetService from '../../../../hoc/with-get-service';
import { compose } from '../../../../../utils';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import fetchAllCoinsAction from '../../../../../actions/getAllCoins.actions';
import fetchCurrenciesFiatAction from '../../../../../actions/getCurrenciesAll.actions';
import fetchCommonPairsAction from '../../../../../actions/getCommonPairs.actions';
import fetchTargetPairsAction from '../../../../../actions/getTargetPairs.actions';
import fetchAllSourcesAction from '../../../../../actions/getAllSources.actions';
import { personalAreaPath, strategyBotsPath } from '../../../../../constants';
import CreateStrategyBotView from './create-strategy-bot-view';
import fetchCreateStrategyBotAction from '../../../../../actions/postCreateStrategyBot.actions';

export class CreateStrategyBotContainer extends Component {
    state = {
        createNewBot: {
            frequencySecondsMax: '',
            frequencySecondsMin: '',
            id: '',
            maxPrice: '',
            maxTradeAmount: '',
            minPrice: '',
            minTradeAmount: '',
            pair: {
                left: '',
                right: '',
            },
            priceFromSource: false,
            priceSource: 'BINANCE',
            status: 'STOPPED',
            userId: null,
        },
        errors: {},
        loading: false,
    };

    componentDidMount() {
        const { fetchAllCoins, fetchCommonPairs, fetchTargetPairs, fetchCurrenciesFiat, fetchAllSources } = this.props;
        fetchAllCoins();
        fetchCommonPairs();
        fetchTargetPairs();
        fetchCurrenciesFiat();
        fetchAllSources();
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchCommonPairs, fetchAllCoins } = this.props;
        const { createNewBot } = this.state;

        if (prevState.createNewBot.priceSource !== createNewBot.priceSource) {
            fetchCommonPairs(createNewBot.priceSource);
            fetchAllCoins(createNewBot.priceSource);
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                [event.target.name]: event.target.value,
            },
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        const { createNewBot } = this.state;

        if (name.props.name === 'id') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                },
            });
        }
    };

    // redirect to bots page
    cancelCreateBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${strategyBotsPath}`);
    };

    switchSourceVolume = e => {
        const { createNewBot } = this.state;

        this.setState({
            createNewBot: {
                ...createNewBot,
                priceFromSource: !!e.target.checked,
            },
        });
    };

    // btn create bot
    submitcreateNewBot = event => {
        event.preventDefault();
        const { t, history, fetchCreateStrategyBot } = this.props;
        const { createNewBot } = this.state;
        const errors = {};

        const {
            frequencySecondsMax,
            frequencySecondsMin,
            id,
            maxPrice,
            maxTradeAmount,
            minPrice,
            minTradeAmount,
            priceSource,
            priceFromSource,
            userId,
        } = createNewBot;

        this.setState({
            createNewBot: {
                ...createNewBot,
            },
        });
        if (userId?.length > 0) {
            if (!Number.isInteger(+userId)) {
                errors.userId = `${t('createBot.valueMustBeInteger')}`;
            }
            if (+userId < 0) {
                errors.userId = `${t('createBot.valueMustBePositive')}`;
            }

            if (userId.length < 1) {
                errors.userIdError = t('error.input', { count: 1 });
            }
        }

        if (!Number.isInteger(+frequencySecondsMin)) {
            errors.frequencySecondsMin = `${t('createBot.valueMustBeInteger')}`;
        }

        if (!Number.isInteger(+frequencySecondsMax)) {
            errors.frequencySecondsMax = `${t('createBot.valueMustBeInteger')}`;
        }
        if (+frequencySecondsMin < 1) {
            errors.frequencySecondsMin = `${t('generals.minValue')} 0`;
        }
        if (+frequencySecondsMax < 1) {
            errors.frequencySecondsMax = `${t('generals.minValue')} 0`;
        }

        if (+frequencySecondsMin >= +frequencySecondsMax) {
            errors.frequencySecondsMin = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (+minTradeAmount <= 0) {
            errors.minTradeAmount = `${t('generals.minValue')} 0`;
        }

        if (+maxTradeAmount <= 0) {
            errors.maxTradeAmount = `${t('generals.minValue')} 0.1`;
        }

        if (+minTradeAmount >= +maxTradeAmount) {
            errors.minTradeAmount = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (!priceFromSource && +minPrice <= 0) {
            errors.minPrice = `${t('generals.minValue')} 0.1`;
        }

        if (!priceFromSource && +maxPrice <= 0) {
            errors.maxPrice = `${t('generals.minValue')} 0.1`;
        }

        if (!priceFromSource && +minPrice >= +maxPrice) {
            errors.minPrice = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (id?.length < 1) {
            errors.pairNameError = t('general.selectFromTheList');
        }

        if (priceSource?.length < 1) {
            errors.sourceError = t('general.selectFromTheList');
        }

        if (Object.keys(errors).length > 0) {
            message.error(t('general.errorFields'), 2);
            return this.setState({
                errors,
            });
        }

        let request = {};
        Object.keys(createNewBot).forEach(key => {
            request = {
                ...request,
                [key]: createNewBot[key] === '' ? null : createNewBot[key],
                userId: createNewBot.userId?.length > 0 ? createNewBot.userId : null,
            };
        });

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 2);
        } else {
            fetchCreateStrategyBot(request, t, history);
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { createNewBot, loading, error, errors } = this.state;
        const { createStrategyBotLoading, allSources, commonPairs, targetPairs } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <CreateStrategyBotView
                createNewBot={createNewBot}
                errors={errors}
                commonPairs={commonPairs}
                targetPairs={targetPairs}
                allSources={allSources}
                createStrategyBotLoading={createStrategyBotLoading}
                inputOnchange={this.inputOnchange}
                selectOnChange={this.selectOnChange}
                cancelCreateBot={this.cancelCreateBot}
                submitcreateNewBot={this.submitcreateNewBot}
                switchSourceVolume={this.switchSourceVolume}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

CreateStrategyBotContainer.defaultProps = {
    t: () => {},
    history: {},
    fetchCreateStrategyBot: () => {},
    fetchAllCoins: () => {},
    fetchAllSources: () => {},
    fetchCurrenciesFiat: () => {},
    fetchCommonPairs: () => {},
    fetchTargetPairs: () => {},
    commonPairs: [],
    targetPairs: [],
    allSources: [],
    createStrategyBotLoading: false,
};

CreateStrategyBotContainer.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    fetchCreateStrategyBot: PropTypes.func,
    fetchAllCoins: PropTypes.func,
    fetchAllSources: PropTypes.func,
    fetchCurrenciesFiat: PropTypes.func,
    fetchCommonPairs: PropTypes.func,
    fetchTargetPairs: PropTypes.func,
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    allSources: PropTypes.arrayOf(PropTypes.object),
    createStrategyBotLoading: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        createStrategyBot: { loading: createStrategyBotLoading },
        allCoins: { allCoins },
        allSources: { allSources },
        commonPairs: { commonPairs },
        targetPairs: { targetPairs },
        currenciesAll: { data: currenciesAll },
    } = state;

    return {
        createStrategyBotLoading,
        allCoins,
        allSources,
        commonPairs,
        targetPairs,
        currenciesAll,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) =>
    bindActionCreators(
        {
            fetchCreateStrategyBot: fetchCreateStrategyBotAction(postService),
            fetchAllCoins: fetchAllCoinsAction(getService),
            fetchCurrenciesFiat: fetchCurrenciesFiatAction(getService),
            fetchCommonPairs: fetchCommonPairsAction(getService),
            fetchTargetPairs: fetchTargetPairsAction(getService),
            fetchAllSources: fetchAllSourcesAction(getService),
        },
        dispatch,
    );

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(CreateStrategyBotContainer);
