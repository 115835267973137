import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactWOW from 'react-wow';
import { Spin } from 'antd';

import Login from '../../../auth/login';
import { compose } from '../../../../utils';
import Img from '../../../UI/img';

import Illustration from './images/illustration.svg';
import style from './first-screen.module.scss';

const FirstScreen = ({ authLoading }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            {authLoading ? <Spin className={style.spin} tip={t('general.loading')} size="large" /> : null}
            <div className="filter">
                <div className={style.firstScreen}>
                    <div className={style.firstScreen__leftSide}>
                        <ReactWOW animation="fadeInUp" delay="0.25s">
                            <div>
                                <Login />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className={style.firstScreen__rightSide}>
                        <ReactWOW animation="fadeInUp" delay="0.25s">
                            <Img
                                className={style.firstScreen__rightSide_illustration}
                                src={Illustration}
                                alt="illustration"
                            />
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => {
    const {
        authentication: { loading: authLoading },
    } = state;

    return {
        authLoading,
    };
};

FirstScreen.defaultProps = {
    authLoading: false,
};

FirstScreen.propTypes = {
    authLoading: PropTypes.bool,
};

export default compose(connect(mapStateToProps))(FirstScreen);
