/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import GreenRedButtonBlock from '../../../../UI/green-red-button-block';
import BotTableRow from '../../../../UI/bot-table-row';
import AreUSureModal from '../../../../UI/are-u-sure-modal';
import Button from '../../../../UI/button';
import style from './orders.module.scss';

const OrdersView = ({
    t,
    orders,
    createNewOrder,
    stopAllOrders,
    startAllOrders,
    switchOrderStatus,
    deleteOrder,
    editOrder,
    cannotChahgeOrder,
    areUSureModal,
    switchAreUSureModal,
    yesBtnAreUSureModal,
}) => (
    <Fragment>
        <div className={style.botsWrapper}>
            <div className={style.botsWrapper__bot}>
                <p className={style.botsWrapper__bot_title}>{t('orders.title')}</p>
                <div className={style.botsWrapper__buttonsWrapper}>
                    <GreenRedButtonBlock
                        greenRedButtonWrapper={style.botsWrapper__greenRedButtonWrapper}
                        botsData={orders}
                        hideIfLessOne
                        clickGreenBtn={createNewOrder}
                        clickRedBtn={stopAllOrders}
                        greenBtnText={t('generals.createNew')}
                        redBtnText={t('generals.stopAll')}
                    />
                    <Button onClick={startAllOrders} className={style.botsWrapper__startAll} type="button">
                        {t('generals.startAll')}
                    </Button>
                </div>
                <BotTableRow
                    botsData={orders}
                    isShowDailyVolume={false}
                    switchStatusProvider={switchOrderStatus}
                    deleteBotHandler={deleteOrder}
                    editProviderBot={editOrder}
                    cannotChahgeBot={cannotChahgeOrder}
                />
            </div>
            <AreUSureModal
                visibleModal={areUSureModal}
                noButton={switchAreUSureModal}
                yesButton={yesBtnAreUSureModal}
            />
        </div>
    </Fragment>
);

OrdersView.defaultProps = {
    t: () => {},
    createNewOrder: () => {},
    stopAllOrders: () => {},
    startAllOrders: () => {},
    switchOrderStatus: () => {},
    deleteOrder: () => {},
    editOrder: () => {},
    cannotChahgeOrder: () => {},
    switchAreUSureModal: () => {},
    yesBtnAreUSureModal: () => {},
    areUSureModal: false,
    orders: '',
};

OrdersView.propTypes = {
    t: PropTypes.func,
    createNewOrder: PropTypes.func,
    stopAllOrders: PropTypes.func,
    startAllOrders: PropTypes.func,
    switchOrderStatus: PropTypes.func,
    deleteOrder: PropTypes.func,
    editOrder: PropTypes.func,
    cannotChahgeOrder: PropTypes.func,
    areUSureModal: PropTypes.bool,
    switchAreUSureModal: PropTypes.func,
    yesBtnAreUSureModal: PropTypes.func,
    orders: PropTypes.any,
};

export default withTranslation()(OrdersView);
