import { message } from 'antd';
import { switchStatusDepthConstants } from '../constants';

const switchStatusDepthRequested = () => ({
    type: switchStatusDepthConstants.SWITCH_STATUS_DEPTH_REQUEST,
});

const switchStatusDepthLoaded = data => ({
    type: switchStatusDepthConstants.SWITCH_STATUS_DEPTH_SUCCESS,
    payload: data,
});

const switchStatusDepthError = error => ({
    type: switchStatusDepthConstants.SWITCH_STATUS_DEPTH_FAILURE,
    payload: error,
});

const switchStatusDepthAction = patchService => (filterBotsdata, id, t) => dispatch => {
    dispatch(switchStatusDepthRequested());
    patchService
        .switchStatusDepth(filterBotsdata, id)
        .then(data => {
            dispatch(switchStatusDepthLoaded(data));
            message.success(t('createBot.statusChanged'), 2);
        })
        .catch(err => {
            dispatch(switchStatusDepthError(err));
        });
};

export default switchStatusDepthAction;
