import { message } from 'antd';
import { createScheduledOrder } from '../constants';

const createScheduledOrderRequested = () => ({
    type: createScheduledOrder.FETCH_CREATE_SCHEDULED_ORDER_REQUEST,
});

const createScheduledOrderLoaded = data => ({
    type: createScheduledOrder.FETCH_CREATE_SCHEDULED_ORDER_SUCCESS,
    payload: data,
});

const createScheduledOrderError = error => ({
    type: createScheduledOrder.FETCH_CREATE_SCHEDULED_ORDER_FAILURE,
    payload: error,
});

const fetchCreateScheduledOrderAction = postService => (scheduled, t) => dispatch => {
    dispatch(createScheduledOrderRequested());
    postService
        .createScheduledOrder(scheduled)
        .then(data => {
            dispatch(createScheduledOrderLoaded(data));
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            dispatch(createScheduledOrderError(err));
        });
};

export default fetchCreateScheduledOrderAction;
