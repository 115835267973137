import { GET_CURRENCIES_ALL } from '../constants';

const getCurrenciesFiatRequested = () => ({
    type: GET_CURRENCIES_ALL.GET_CURRENCIES_ALL_REQUEST,
});

const getCurrenciesFiatLoaded = data => ({
    type: GET_CURRENCIES_ALL.GET_CURRENCIES_ALL_SUCCESS,
    payload: data,
});

const getCurrenciesFiatError = error => ({
    type: GET_CURRENCIES_ALL.GET_CURRENCIES_ALL_FAILURE,
    payload: error,
});

const fetchCurrenciesFiatAction = getService => () => dispatch => {
    dispatch(getCurrenciesFiatRequested());
    getService
        .getCurrenciesAll()
        .then(data => {
            dispatch(getCurrenciesFiatLoaded(data));
        })
        .catch(err => {
            dispatch(getCurrenciesFiatError(err));
        });
};

export default fetchCurrenciesFiatAction;
