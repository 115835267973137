import { workLoadTimeLine } from '../constants';

const getWorkLoadTimeLineRequested = () => ({
    type: workLoadTimeLine.WORK_LOAD_TIME_LINE_REQUEST,
});

const getWorkLoadTimeLineLoaded = data => ({
    type: workLoadTimeLine.WORK_LOAD_TIME_LINE_SUCCESS,
    payload: data,
});

const getWorkLoadTimeLineError = error => ({
    type: workLoadTimeLine.WORK_LOAD_TIME_LINE_FAILURE,
    payload: error,
});

const workLoadTimeLineAction = getService => (pairName, chartType) => dispatch => {
    dispatch(getWorkLoadTimeLineRequested());
    getService
        .getWorkLoadTimeLine(pairName, chartType)
        .then(data => {
            dispatch(getWorkLoadTimeLineLoaded(data));
        })
        .catch(err => {
            dispatch(getWorkLoadTimeLineError(err));
        });
};

export default workLoadTimeLineAction;
