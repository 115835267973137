import { AVAILABLE_PAIRS_CONSTANTS } from '../constants';

const availableNewPairs = (state, action) => {
    if (state === undefined) {
        return {
            data: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
        case AVAILABLE_PAIRS_CONSTANTS.GET_AVAILABLE_PAIRS_REQUEST:
            return {
                data: [],
                loading: true,
                error: false,
            };

        case AVAILABLE_PAIRS_CONSTANTS.GET_AVAILABLE_PAIRS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: false,
            };

        case AVAILABLE_PAIRS_CONSTANTS.GET_AVAILABLE_PAIRS_FAILURE:
            return {
                data: [],
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};

export default availableNewPairs;
